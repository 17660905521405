import React, { Component } from 'react';
import { translate } from 'react-translate';
import { Form, FormGroup, CustomInput, Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import InputRange from 'react-input-range';

import 'react-input-range/lib/css/index.css';
import closeWhite from './images/close-white.png';

class Filters extends Component {
    constructor(props) {
        super(props);

        this.state = {
            filters: this.initFilters(),
            filtersDisabled: this.initFilters(true)
        }
    }

    initFilters(_filtersDisabled) {
        let filters = new Map();
        let filtersDisabled = new Map();

        this.props.settings.forEach((filter, index) => {
            switch (filter.type) {
                case 'range':
                    filters.set('filter-' + index, { min: filter.minValue, max: filter.maxValue });

                    if (filter.disabled) {
                        filtersDisabled.set('filter-' + index + '-disabled', true);
                    }
                    break;
                case 'checkbox':
                    filters.set('filter-' + index, false);
                    break;
                default:
                    break;
            }
        });

        if (_filtersDisabled) {
            return filtersDisabled;
        }

        return filters;
    }

    closeModal() {
        this.props.setShowFilters(false);
    }

    checkboxChange(event) {
        const item = event.target.id;
        const isChecked = event.target.checked;

        this.setState(
            prevState => (
                {
                    filters: prevState.filters.set(item, isChecked)
                }
            )
        );
    }

    checkboxDisabledChange(event) {
        const item = event.target.id;
        const isChecked = event.target.checked;

        this.setState(
            prevState => (
                {
                    filtersDisabled: prevState.filtersDisabled.set(item, isChecked)
                }
            )
        );
    }

    rangeChange(item, value) {
        this.setState(
            prevState => (
                {
                    filters: prevState.filters.set(item, value)
                }
            )
        );
    }

    clean() {
        this.setState({
            filters: this.initFilters(),
            filtersDisabled: this.initFilters(true)
        });

        this.props.applyFilters('INCLUDE', []);
        this.props.setShowFilters(false);
    }

    apply() {
        let filters = [];
        let query = [];

        this.state.filters.forEach((value, key) => {
            const setting = this.props.settings[key.replace('filter-', '')];

            switch (setting.type) {
                case 'range':
                    const _disabled = (setting.disabled === null || setting.disabled === undefined || setting.disabled === '') ? false : true;
                    let _filter = setting.filter.replace('{rangeMin}', value.min).replace('{rangeMax}', value.max);
                    let _query = setting.query.replace('{rangeMin}', value.min).replace('{rangeMax}', value.max);

                    if (!_disabled || (_disabled && !this.state.filtersDisabled.get(key + '-disabled'))) {
                        filters.push(_filter);
                        query.push(_query);
                    }
                    break;
                case 'checkbox':
                    if (value) {
                        filters.push(setting.filter);
                    }

                    query.push(setting.query.replace('{checked}', value));
                    break;
                default:
                    break;
            }
        });

        this.props.applyFilters(filters.join(' and '), query.join('&'));
        this.props.setShowFilters(false);
    }

    componentDidMount() {
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.resetFilters !== prevProps.resetFilters) {
            this.clean();
        }
    }

    render() {
        let { t } = this.props;
        const btnClose = 
            <button type="button" className="action small red" onClick={() => this.closeModal()}>
                <span className="content">
                    <span className="line">
                        <span className="circle">
                            <img src={closeWhite} className="img-fluid mx-auto d-block" alt={t('Close icon')} />
                        </span>
                    </span>
                </span>
                <span className="sr-only">{t('Close')}</span>
            </button>;

        return (
            <Modal isOpen={this.props.show} toggle={() => this.closeModal()} size="lg" className="filters-box" centered={true} wrapClassName={'rotation ' + this.props.orientation} modalClassName="rotate">
                <ModalHeader toggle={() => this.closeModal()} close={btnClose} className="filters-title" tag="h4">
                    {t('Filters')}
                </ModalHeader>
                <ModalBody className="filters-container">
                    <Form>
                        {
                            this.props.settings.map((filter, index) => (
                                (
                                    filter.type === 'range' &&
                                    <FormGroup key={index}>
                                        <div className="h6">{filter.name}</div>
                                        {
                                            filter.disabled &&
                                            <CustomInput 
                                                type="checkbox" 
                                                label={filter.disabled} 
                                                value={filter.disabled} 
                                                name={'filter-' + index + '-disabled'} 
                                                id={'filter-' + index + '-disabled'} 
                                                onChange={(e) => this.checkboxDisabledChange(e)}
                                                checked={this.state.filtersDisabled.get('filter-' + index + '-disabled')}
                                            />
                                        }
                                        <div className={this.state.filtersDisabled.get('filter-' + index + '-disabled') ? 'range disabled': 'range'}>
                                            <InputRange
                                                id={'filter-' + index} 
                                                maxValue={filter.maxValue}
                                                minValue={filter.minValue}
                                                value={this.state.filters.get('filter-' + index)}
                                                onChange={(value) => this.rangeChange('filter-' + index, value)}
                                                formatLabel={value => `${value}${filter.unit}`}
                                            />
                                        </div>
                                    </FormGroup>
                                )
                                ||
                                (
                                    filter.type === 'checkbox' &&
                                    <FormGroup  key={index}>
                                        <CustomInput 
                                            type="checkbox" 
                                            label={filter.name} 
                                            value={filter.name} 
                                            name={'filter-' + index} 
                                            id={'filter-' + index} 
                                            onChange={(e) => this.checkboxChange(e)}
                                            checked={this.state.filters.get('filter-' + index)}
                                        />
                                    </FormGroup>
                                )
                            ))
                        }
                    </Form>
                </ModalBody>
                <ModalFooter className="modal-actions">
                    <Button color="primary" onClick={() => this.apply()}>
                        {t('Accept')}
                    </Button>
                    <Button color="secondary" onClick={() => this.clean()}>
                        {t('Clean')}
                    </Button>
                </ModalFooter>
            </Modal>
        );
    }
}

export default translate('Filters')(Filters);