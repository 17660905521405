import React from 'react';
import ReactDOM from 'react-dom';
import { TranslatorProvider } from 'react-translate';
import 'animate.css/animate.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

const configPromise = fetch(process.env.PUBLIC_URL + '/config.json');
const lang = 'es';

configPromise
    .then((response) => response.json())
    .then((config) => 
        ReactDOM.render(
            <TranslatorProvider translations={require('./i18n/' + lang + '.json')}><App config={config} /></TranslatorProvider>,
            document.getElementById('root')
        )
    )
    .catch((error) => {
        console.log(error);
        ReactDOM.render(
            <TranslatorProvider translations={require('./i18n/' + lang + '.json')}><App error="true" /></TranslatorProvider>,
            document.getElementById('root')
        )
    });

//ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
