import React, { Component } from 'react';
import Keyboard from 'react-simple-keyboard';
import Highlighter from "react-highlight-words";
import ScrollArea from 'react-scrollbar';
import { translate } from 'react-translate';
import { Input, Spinner as BSpinner, ListGroup, ListGroupItem, Button } from 'reactstrap';
import ScrollMenu from 'react-horizontal-scrolling-menu';
import { Animated } from "react-animated-css";

import 'react-simple-keyboard/build/css/index.css';
import closeWhite from './images/close-white.png';

class Search extends Component {
    constructor(props) {
        super(props);

        this.state = {
            search: '',
            searching: false,
            loading: false,
            results: [],
            show: true,
            fadeIn: 100,
            keyboardTranslateY: 0,
            keyboardHeight: 0,
            marginCenter: 0,
            suggestionsHeight: 0,
            layoutName: 'default'
        }

        this.searching = null;
        this.keyboard = React.createRef();
    }

    componentDidMount() {
        const keyboardTranslateY = (this.refs.search.offsetWidth / 2) - (this.refs.search.offsetHeight / 2);

        this.setState(
            {
                keyboardTranslateY: keyboardTranslateY,
                suggestionsHeight: this.refs.suggestions ? this.refs.suggestions.offsetHeight : 0
            }
        );
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.itemsChange !== prevProps.itemsChange) {
            if (this.props.data.length === 0 || this.props.itemsChange.indexOf('Search - ') !== -1) {
                if (this.props.itemsChange.indexOf('Hidden - Search - ') !== -1) {
                    this.setState(
                        {
                            show: false
                        }
                    );
                } else {
                    if (this.props.itemsChange.indexOf('Graphics - ') === -1) {
                        if (this.props.virtualKeyboard) {
                            this.keyboard.current.setInput('');
                        }

                        this.setState(
                            {
                                show: true,
                                search: '',
                                searching: false,
                                results: []
                            },
                            () => setTimeout(() => {
                                this.reposition();
                            }, this.state.fadeIn + 1)
                        );
                    }
                }
            } else {
                if (this.state.show && this.props.itemsChange.indexOf('Graphics - ') === -1) {
                    this.setState(
                        {
                            show: false
                        }
                    );
                }
            }
        }

        if (this.props.searchReposition !== prevProps.searchReposition) {
            this.reposition();
        }
    }

    onRender() {
        this.setState(
            {
                keyboardHeight: this.props.virtualKeyboard ? this.refs.keyboard.offsetHeight : 0
            }
        );

        this.reposition();
    }

    reposition() {
        const marginCenter = ((this.props.position.height - this.refs.search.offsetHeight - this.state.suggestionsHeight) / 2) - (this.props.virtualKeyboard && this.refs.keyboard ? this.refs.keyboard.offsetHeight / 2 : 0);

        this.setState(
            {
                marginCenter: marginCenter
            }
        );
    }

    searchChange(event) {
        this.setState(
            {
                search: event.target.value.trim(),
                searching: event.target.value.trim().length > 0 ? true : false,
                loading: event.target.value.trim().length > 0 ? true : false
            },
            () => setTimeout(() => {
                this.search();
                this.reposition();
            }, 150)
        );
    }

    onChange(input) {
        this.setState(
            {
                search: input.trim(),
                searching: input.trim().length > 0 ? true : false,
                loading: input.trim().length > 0 ? true : false
            },
            () => setTimeout(() => {
                this.search();
                this.reposition();
            }, 100)
        );
    }

    onKeyPress(button) {
        if (button === '{shift}' || button === '{lock}') this.handleShift();
    };

    handleShift() {
        const layoutName = this.state.layoutName;
    
        this.setState({
            layoutName: layoutName === 'default' ? 'shift' : 'default'
        });
    };

    search() {
        clearTimeout(this.searching);

        this.searching = setTimeout(() => {
            if (this.state.search.length > 0) {
                (async () => {
                    let _url = this.props.getData.info.replace('{search}', encodeURIComponent(this.state.search));

                    if (this.props.getData.infoUseFilters) {
                        _url = this.props.urlQueryFilters(_url);
                    }

                    const response = await fetch(_url);

                    if (response.status === 200 && response.ok) {
                        const results = await response.json();
                        this.setState(
                            {
                                results: results,
                                loading: false
                            }
                        );
                    } else {
                        this.setState(
                            {
                                results: [],
                                loading: false
                            }
                        );
                    }
                })()
                    .catch(reason => {
                        this.setState(
                            {
                                results: [],
                                loading: false
                            }
                        );
                    });
            } else {
                this.setState(
                    {
                        results: [],
                        loading: false
                    }
                );
            }
        }, 500);
    }

    addItem(id, name, info) {
        this.setState(
            {
                show: false
            },
            () => this.props.addItem(id, name, info)
        );
    }

    getMenu(list) {
        return list.map(item => {
            const {id, label, info} = item;

            return (
                <Button
                    color="secondary" 
                    size="sm" 
                    key={id} 
                    tag="div" 
                    outline
                    onClick={() => this.addItem(id, label, info)}
                >
                    {label}
                </Button>
            );
        })
    }

    close() {
        this.props.showSearch();
    }

    render() {
        let { t } = this.props;
        let translateSuggestions = 0;

        if (this.props.orientation === 'left') {
            translateSuggestions = this.props.data.length > 0 ? 0 : -1 * this.state.suggestionsHeight;
        }

        if (this.props.orientation === 'right') {
            translateSuggestions = this.props.data.length > 0 ? -1 * this.state.suggestionsHeight : 0;
        }

        return (
            <Animated 
                className={
                    'search-box rotation ' + this.props.orientation +
                    (this.props.virtualKeyboard ? ' active-keyboard' : '') + 
                    (this.state.searching ? ' searching' : '') +
                    (this.props.data.length > 0 ? ' right-arrow' : '')
                }
                style={{ 
                    top: this.props.position.top, 
                    left: this.props.position.x + (this.props.data.length > 0 ? 0 : this.props.position.width), 
                    '--keyboard-translate-y': (this.state.keyboardTranslateY + translateSuggestions) + 'px', 
                    '--keyboard-height': this.state.keyboardHeight + 'px', 
                    '--search-margin-center': this.state.marginCenter + 'px'
                }}
                animationIn="fadeIn" 
                animationOut="fadeOut" 
                animationInDuration={this.state.fadeIn} 
                animationOutDuration={this.state.fadeIn} 
                isVisible={this.state.show}
            >
                <div className="search-container rotate" ref="search">
                    <div className="search-bg">
                        <div className="container">
                            <button type="button" className="action" onClick={() => this.close()}>
                                <span className="content">
                                    <span className="line">
                                        <span className="circle">
                                            <img src={closeWhite} className="img-fluid mx-auto d-block" alt={t('Close icon')} />
                                        </span>
                                    </span>
                                </span>
                                <span className="sr-only">{t('Close')}</span>
                            </button>
                            <div className="row align-items-center">
                                {
                                    this.state.search.length === 0 &&
                                    <div className="col-2">
                                        <div className="icon">
                                            <img src={process.env.PUBLIC_URL + this.props.image} className="img-fluid mx-auto d-block" alt={t('Image')} />
                                        </div>
                                    </div>
                                }
                                <div className={'text-left ' + (this.state.search.length === 0 ? 'col-9' : 'col-12') }>
                                    <div className="d-flex">
                                        {
                                            this.props.virtualKeyboard &&
                                            <div className="text">
                                                {this.state.search.length === 0 ? this.props.text : this.state.search}
                                            </div>
                                        }
                                        {
                                            !this.props.virtualKeyboard &&
                                            <Input  type="text" placeholder={this.props.text} value={this.state.search} onChange={(event) => this.searchChange(event)}/>
                                        }
                                    </div>
                                </div>
                            </div>
                            <Animated 
                                className="row results"
                                animationIn="fadeIn" 
                                animationOut="fadeOut" 
                                animationInDuration={50} 
                                animationOutDuration={50} 
                                isVisible={this.state.search.length !== 0 ? true : false}
                            >
                                {
                                    !this.state.loading &&
                                    <ScrollArea
                                        speed={0.8}
                                        className="list"
                                        contentClassName="content"
                                        horizontal={true} 
                                    >
                                        <ListGroup tag="div" flush>
                                            {
                                                this.state.results.map((item, index) => (
                                                    <ListGroupItem 
                                                        tag="button" 
                                                        className="list-item text-left" 
                                                        action key={index} 
                                                        onClick={() => this.addItem(item.id.toString(), item[this.props.getData.variables.name], item[this.props.getData.variables.info])}
                                                    >
                                                        <Highlighter
                                                            highlightClassName="highlight"
                                                            searchWords={this.state.search ? this.state.search.split(' ') : []}
                                                            autoEscape={true}
                                                            textToHighlight={ item[this.props.getData.variables.name] ? item[this.props.getData.variables.name] : '' }
                                                        />
                                                    </ListGroupItem>
                                                ))
                                            }
                                            {
                                                this.state.results.length === 0 &&
                                                <ListGroupItem className="list-item" action disabled>
                                                    { t('No results found') }
                                                </ListGroupItem>
                                            }
                                        </ListGroup>
                                    </ScrollArea>
                                }
                                <Animated 
                                    className="status d-flex justify-content-center align-items-center" 
                                    animationIn="fadeIn" 
                                    animationOut="fadeOut" 
                                    animationInDuration={500} 
                                    animationOutDuration={500} 
                                    isVisible={this.state.loading ? true : false}
                                >
                                    <div className="text-center">
                                        <BSpinner type="grow">{t('Searching')}</BSpinner>
                                        <div>
                                            {t('Searching')}
                                        </div>
                                    </div>
                                </Animated>
                            </Animated>
                        </div>
                    </div>
                    {
                        this.props.getData.suggestions && 
                        this.props.getData.suggestions.length > 0 &&
                        this.state.search.length === 0 &&
                        <div className={'suggestions' + (this.props.virtualKeyboard ? ' virtual' : '')} ref="suggestions">
                            <ScrollMenu
                                data={this.getMenu(this.props.getData.suggestions)} 
                                hideArrows={true} 
                                alignCenter={false}
                            />
                        </div>
                    }
                    {
                        this.props.virtualKeyboard &&
                        this.state.show &&
                        <div className="keyboard" ref="keyboard">
                            <Keyboard 
                                keyboardRef={r => (this.keyboard.current = r)}
                                onRender={() => this.onRender()}
                                onChange={(input) => this.onChange(input)} 
                                layoutName={this.state.layoutName} 
                                onKeyPress={(button) => this.onKeyPress(button)}
                            />
                        </div>
                    }
                </div>
            </Animated>
        );
    }
}

export default translate('Search')(Search);
