import React, { Component } from 'react';
import ReactDOMServer from 'react-dom/server';
import hexToRgba from 'hex-to-rgba';
import Leap from 'leapjs';
import { Map as OlMap, View as OlView, Feature as OlFeature } from 'ol';
import { Tile as OlLayerTile, Group as OlLayerGroup, Vector as OlLayerVector } from 'ol/layer';
import { OSM as OlSourceOSM, Vector as OlSourceVector, TileWMS as OlSourceTileWMS, XYZ as OlSourceTileXYZ, TileJSON as OlSourceTileJSON } from 'ol/source';
import { Style as olStyle, Icon as olStyleIcon, Text as olStyleText, Fill as olStyleFill, Stroke as olStyleStroke } from 'ol/style';
import { Control, defaults as defaultControls, Attribution } from 'ol/control';
import { Point as olGeomPoint, Polygon as olGeomPolygon, MultiPolygon as olGeomMultiPolygon } from 'ol/geom';
import { transform } from 'ol/proj';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';
import { translate } from 'react-translate';
import { Spinner as BSpinner } from 'reactstrap';
import XMLParser from 'react-xml-parser';
import { Animated } from "react-animated-css";

import Filters from './Filters';
import Dot from './images/dot.png';
import MapI from './images/map.png';
import Satellite from './images/satellite.png';
import Help from './images/help.png';

import PopAudio from './sounds/pop.wav';
import ZoomAudio from './sounds/swoosh.wav';

class ViewMap extends Component {
    leftHandPrev = null;
    zoomLevelAtCircleStart = null;
    separationStart = null;
    constructor(props) {
        super(props);

        let centerExtent = null;
        let olView = new OlView({
            center: transform(props.config.center, 'EPSG:4326', 'EPSG:3857'),
            zoom: props.config.zoom
        });

        if (props.config.centerExtent) {
            centerExtent = new OlFeature({
                geometry: props.config.centerExtent.type === 'Polygon' ? new olGeomPolygon(props.config.centerExtent.coordinates) : new olGeomMultiPolygon(props.config.centerExtent.coordinates)
            });
        }

        this.state = {
            center: transform(props.config.center, 'EPSG:4326', 'EPSG:3857'),
            zoom: props.config.zoom,
            layerData: null,
            sources: null,
            layers: null,
            layerGroup: null,
            view: olView,
            vectorSource: null,
            vectorLayer: null,
            modalMsg: '',
            showModal: false,
            centerExtent: centerExtent,
            loading: false,
            loadingData: false,
            satellite: false,
            maxZoom: props.config.maxZoom,
            separationScaling: 1.25
        };

        this.areaInfo = new Map();

        this.LegendsControl = (function (Control) {
            function LegendsControl(opt_options) {
                let options = opt_options || {};

                const legends = (
                    <div className="bar-legends">
                        <div className="bar-header">
                            {opt_options.title}
                        </div>
                        <div className="bar-content">
                            <ul>
                                {
                                    opt_options.items.map((item, index) => (
                                        <li key={index}>
                                            <span className="icon" style={{backgroundColor: item.point.color, borderColor: item.point.border}}></span>
                                            <span className="text">{item.name}</span>
                                        </li>
                                    ))
                                }
                            </ul>
                        </div>
                    </div>
                );

                const element = document.createElement('div');

                element.className = 'legends rotate ol-unselectable ol-control disable-doubletap-to-zoom';
                element.innerHTML = ReactDOMServer.renderToString(legends);

                Control.call(this, {
                    element: element,
                    render: function() {
                        const rect = this.element.getBoundingClientRect();
                        this.element.style = '--legends-width:-' + rect.width + 'px; --legends-height:' + rect.height + 'px;';
                    },
                    target: options.target
                });
            }

            if ( Control ) LegendsControl.__proto__ = Control;
            LegendsControl.prototype = Object.create(Control && Control.prototype);
            LegendsControl.prototype.constructor = LegendsControl;

            return LegendsControl;
        }(Control));

        this.LayerControl = (function (Control) {
            function LayerControl(opt_options) {
                let options = opt_options || {};

                const text = (
                    <span className="content">
                        <img className="img-fluid mx-auto d-block" src={opt_options.modeSatellite ? opt_options.images.map : opt_options.images.satellite} alt="Imagen" />
                        <span className="text">
                            {opt_options.modeSatellite ? opt_options.map : opt_options.satellite}
                        </span>
                    </span>
                );

                const container = document.createElement('div');
                const button = document.createElement('button');

                container.className = 'layer rotate ol-control disable-doubletap-to-zoom';
                container.appendChild(button);
                button.innerHTML = ReactDOMServer.renderToString(text);

                Control.call(this, {
                    element: container,
                    target: options.target
                });

                button.addEventListener('click', opt_options.onClick.bind(this), false);
            }

            if ( Control ) LayerControl.__proto__ = Control;
            LayerControl.prototype = Object.create(Control && Control.prototype);
            LayerControl.prototype.constructor = LayerControl;

            return LayerControl;
        }(Control));

        this.HelpControl = (function (Control) {
            function HelpControl(opt_options) {
                let options = opt_options || {};

                const content = (
                    <img className="img-fluid d-block" src={Help} alt="Help" />
                );

                const container = document.createElement('div');
                const button = document.createElement('button');

                container.className = 'help rotate ol-control disable-doubletap-to-zoom';
                container.appendChild(button);
                button.innerHTML = ReactDOMServer.renderToString(content);

                Control.call(this, {
                    element: container,
                    target: options.target
                });

                button.addEventListener('click', opt_options.onClick.bind(this), false);
            }

            if ( Control ) HelpControl.__proto__ = Control;
            HelpControl.prototype = Object.create(Control && Control.prototype);
            HelpControl.prototype.constructor = HelpControl;

            return HelpControl;
        }(Control));

        this.attribution = new Attribution({
            collapsible: true
        });

        this.olmap = new OlMap({
            target: null,
            controls: defaultControls({attribution: false, zoom: false}).extend([this.attribution]),
            view: this.state.olView
        });

        this.helpControl = new this.HelpControl({
            onClick: () => this.props.showOverlay(),
        });
        this.olmap.addControl(this.helpControl);
        this.popAudio = new Audio(PopAudio);
        this.state.zoomAudio = new Audio(ZoomAudio);
    }

    toggleSatelliteLayer() {
        this.setState(
            {
                satellite: !this.state.satellite
            },
            () => this.toggleLayerControl()
        );
    }

    toggleLayerControl() {
        let { t } = this.props;

        if (this.layerControl) {
            this.olmap.removeControl(this.layerControl);
        }

        this.layerControl = new this.LayerControl({
            map: t('Map'),
            satellite: t('Satellite'),
            onClick: (event) => this.toggleSatelliteLayer(event),
            modeSatellite: this.state.satellite,
            images: {
                map: MapI,
                satellite: Satellite
            }
        });
        this.olmap.addControl(this.layerControl);
        this.setLayers();
    }

    setLayers() {
        let baseSource = new OlSourceOSM();
        let centerExtent = null;
        let baseLayer = this.state.satellite ? this.props.config.satelliteLayer : this.props.baseLayer;

        if (this.props.config.centerExtent) {
            centerExtent = new OlFeature({
                geometry: this.props.config.centerExtent.type === 'Polygon' ? 
                            new olGeomPolygon(this.props.config.centerExtent.coordinates) : 
                            new olGeomMultiPolygon(this.props.config.centerExtent.coordinates)
            });
        }

        this.olmap.getView().setMinZoom(this.state.minZoom);
        this.olmap.getView().setMaxZoom(this.state.maxZoom);

        if (baseLayer) {
            switch (baseLayer.type) {
                case "XYZ":
                    baseSource = new OlSourceTileXYZ({ 
                        url: baseLayer.url,
                        crossOrigin: 'anonymous'
                    });
                    break;
                case "JSON":
                    baseSource = new OlSourceTileJSON({ 
                        url: baseLayer.url,
                        crossOrigin: 'anonymous'
                    });
                    break;
                default:
                    baseSource = new OlSourceOSM();
                    break;
            }
        }

        let sources = [
            {
                "name": "default",
                "source": baseSource
            }
        ];

        this.props.layers.forEach(layer => {
            let source = {
                url: layer.url,
                serverType: 'geoserver',
                transition: 0,
                crossOrigin: 'anonymous'
            };

            if (layer.params) {
                source.params = layer.params;

                if (!source.params['CQL_FILTER']) {
                    source.params['CQL_FILTER'] = 'INCLUDE';
                }
            } else {
                source.params = {
                    'CQL_FILTER': 'INCLUDE'
                }
            }

            if (layer.compare && this.props.typeInteraction === 'search') {
                let value = ''

                this.props.items.forEach((item, index) => {
                    value += layer.compare.query.replace(/{index}/g, index + 1).replace(/{color}/g, item.settings.color.replace('#', '')).replace(/{id}/g, item.id);
                });

                source.params[layer.compare.param] = value;
            }

            sources.push(
                {
                    name: layer.name,
                    source: new OlSourceTileWMS(source),
                    params: source.params,
                    featureId: layer.featureId,
                    notAddItem: layer.notAddItem ? layer.notAddItem : false
                }
            );
        });

        let layers = [];

        sources.forEach(source => {
            layers.push(
                new OlLayerTile({
                    source: source.source
                })
            );
        });

        let vectorSource = new OlSourceVector();
        let vectorLayer = new OlLayerVector({
            source: vectorSource
        });

        layers.push(vectorLayer);

        let layerGroup = new OlLayerGroup({
            layers: layers
        });

        if (this.legendsControl) {
            this.olmap.removeControl(this.legendsControl);
        }

        if (this.props.legends) {
            this.legendsControl = new this.LegendsControl(this.props.legends);
            this.olmap.addControl(this.legendsControl);
        }

        this.setState(
            {
                layerData: this.props.layers.length,
                sources: sources,
                layers: layers,
                layerGroup: layerGroup,
                vectorSource: vectorSource,
                vectorLayer: vectorLayer,
                centerExtent: centerExtent
            },
            () => {
                this.olmap.setLayerGroup(this.state.layerGroup);
            }
        );
    }

    applyFilters(cqlFilter, query) {
        this.state.layers.forEach((layer, index) => {
            if (0 < index && index < this.state.layers.length - 1) {
                let params = this.state.sources[index].params;

                params['CQL_FILTER'] = cqlFilter;
                layer.getSource().updateParams({
                    params: params
                });
            }
        });

        this.props.applyQueryFilters(query);
    }

    updateMap() {
        this.olmap.getView().setCenter(this.state.center);
        this.olmap.getView().setZoom(this.state.zoom);

        if (this.centerPolygon) {
            this.olmap.getView().fit(this.centerPolygon, {padding: [250, 250, 250, 250]});
            this.centerPolygon = null;
        }
    }

    checkPosition(evt) {
        if (this.state.layerData === 0) {
            return false;
        }

        if (evt.pointerEvent.target.tagName.toUpperCase() === 'BUTTON' || evt.pointerEvent.target.tagName.toUpperCase() === 'SPAN') {
            return false;
        }

        if (this.props.typeInteraction === 'search') {
            return false;
        }

        const viewProjection = this.olmap.getView().getProjection();
        const viewResolution = this.olmap.getView().getResolution();
        const source = this.state.sources[this.state.layerData];
        const url = source.source.getFeatureInfoUrl(
            evt.coordinate, viewResolution, viewProjection,
            {
                'INFO_FORMAT': 'application/json',
                'QUERY_LAYERS': source.params.layers
            }
        );

        if (url) {
            this.setState(
                {
                    loading: true
                }
            );

            fetch(url.replace('http:', 'https:'))
                .then((response) => response.json())
                .then((data) => {
                    setTimeout(() => {
                        this.setState(
                            {
                                loading: false
                            }
                        );
                    }, 250);

                    const features = data.features;
                    let feature = null;
                    let id = null;

                    if (features !== undefined && features.length > 0) {
                        feature = features[0];
                        id = feature.id ? feature.id.replace(source.featureId, '') : null;
                    }

                    if (feature !== null && id !== null) {
                        if (source.notAddItem) {
                            const check = source.notAddItem;

                            if (!feature.properties || (feature.properties && feature.properties[check.propertieCheck] && check.rejectionValues.includes(feature.properties[check.propertieCheck]))) {
                                try {
                                    this.popAudio.play();
                                } catch(_) {}

                                this.setState(
                                    {
                                        modalMsg: check.message,
                                        showModal: true
                                    }
                                );

                                return false;
                            }
                        }

                        switch (this.props.typeInteraction) {
                            case 'spinner':
                                this.props.addSpinner(id, evt.pixel, feature.geometry, this.state.center);
                                break;
                            case 'area':
                                this.props.drawArea(id, feature.properties, feature.geometry);
                                break;
                            default:
                                break;
                        }
                    }
                })
                .catch(error => {
                    setTimeout(() => {
                        this.setState(
                            {
                                loading: false
                            }
                        );
                    }, 250);
                    console.log(error);
                });
        } else {
            this.setState(
                {
                    loading: false
                }
            );
        }
    }

    loadAreaInfo(id, url, status, _data) {
        let _status = status;

        if (_status.length === 0) {
            this.areaInfo.set(id, _data);

            this.setState(
                {
                    loadingData: false
                },
                () => this.drawFeatures()
            );
        } else {
            _status = _status.reverse();

            let item = _status.pop();
            let _url = url.replace('{id}', id).replace('{status}', item.value);

            fetch(_url)
                    .then((response) => response.text())
                    .then((text) => {
                        try {
                            let xml = new XMLParser().parseFromString(text);
                            let numberOfFeatures = (xml.getElementsByTagName('wfs:FeatureCollection')[0]).attributes.numberOfFeatures;

                            _data.push({
                                label: item.label,
                                value: parseInt(numberOfFeatures)
                            });
                        } catch (_) {
                        }

                        this.loadAreaInfo(id, url, _status.reverse(), _data);
                    }).catch(error => {
                        console.log(error);
                        this.loadAreaInfo(id, url, _status.reverse(), _data);
                    });
        }
    }

    drawFeatures() {
        this.state.vectorSource.clear();
        let features = [];
        let { t } = this.props;

        this.props.items.forEach((item) => {
            switch (this.props.typeInteraction) {
                case 'spinner':
                    const iconStyle = new olStyle({
                        image: new olStyleIcon({
                            color: item.settings.color,
                            src: Dot
                        }),
                        zIndex : 4,
                    });

                    let featurePoint = new OlFeature(
                        new olGeomPoint(item.geometry.coordinates)
                    );

                    featurePoint.setStyle(iconStyle);
                    featurePoint.setId(item.id);
                    features.push(featurePoint);
                    break;
                case 'area':
                    let treesText = false;
                    let trees = 0;
                    let statusInfo = '';
                    let areaInfo = this.areaInfo.get(item.id);

                    if (item.graphics === null) {
                        if (areaInfo === undefined) {
                            this.setState({
                                loadingData: true
                            });

                            if (item.geometry.type === 'Feature' && this.props.items[this.props.items.length - 1].id === item.id) {
                                this.centerPolygon = item.geometry.feature.getGeometry();
                            }

                            this.loadAreaInfo(item.id, this.props.areaInfo.url, [...this.props.areaInfo.status], []);
                        }
                    }

                    if (areaInfo) {
                        let texts = [];

                        treesText = true;

                        areaInfo.forEach((status) => {
                            trees = trees + status.value;
                            texts.push(status.label + ': ' + status.value);
                        });

                        statusInfo = texts.join('\n');
                    }

                    const areaStyle = new olStyle({
                        stroke: new olStyleStroke({
                            color: item.settings.color,
                            width: 3
                        }),
                        fill: new olStyleFill({
                            color: hexToRgba(item.settings.color, '0.5')
                        }),
                        text: new olStyleText({
                            textAlign: 'center',
                            textBaseline: 'middle',
                            font: 'bold ' + this.props.sizeBase + 'px/1.3 Arial',
                            text: (treesText ? t('Trees') + '\n' : '') + item.information.name,
                            fill: new olStyleFill({
                                color: item.settings.textColor
                            }),
                            stroke: new olStyleStroke({
                                color: item.settings.textBorder,
                                width: 4
                            }),
                            overflow: true
                        })
                    });

                    const treesStyle = new olStyle({
                        text: new olStyleText({
                            textAlign: 'center',
                            textBaseline: 'middle',
                            font: 'bold ' + this.props.sizeBase * 1.5 + 'px/1.3 Arial',
                            text: trees.toString(),
                            fill: new olStyleFill({
                                color: item.settings.textColor
                            }),
                            stroke: new olStyleStroke({
                                color: item.settings.textBorder,
                                width: 4
                            }),
                            overflow: true,
                            offsetY: (-1 * (this.props.sizeBase * 2)) - 2
                        })
                    });

                    const areaInfoStyle = new olStyle({
                        text: new olStyleText({
                            textAlign: 'center',
                            textBaseline: 'middle',
                            font: 'bold ' + this.props.sizeBase * 0.75 + 'px/1.3 Arial',
                            text: statusInfo,
                            fill: new olStyleFill({
                                color: item.settings.textColor
                            }),
                            stroke: new olStyleStroke({
                                color: item.settings.textBorder,
                                width: 4
                            }),
                            overflow: true,
                            offsetY: 1 * (this.props.sizeBase * 3)
                        })
                    });

                    let featurePolygon = null;

                    if (item.geometry.type === 'Feature') {
                        featurePolygon = item.geometry.feature;
                    } else {
                        featurePolygon = new OlFeature({
                            geometry: item.geometry.type === 'Polygon' ? 
                                new olGeomPolygon(item.geometry.coordinates) : 
                                new olGeomMultiPolygon(item.geometry.coordinates),
                            population: 200,
                            rainfall: 40
                        });
                    }

                    featurePolygon.setStyle(treesText ? [areaStyle, treesStyle, areaInfoStyle] : areaStyle);
                    featurePolygon.setId(item.id);
                    features.push(featurePolygon);
                    break;
                default:
                    break;
            }
        });

        if (features.length > 0) {
            this.state.vectorSource.addFeatures(features);
        }
    }

    closeModal() {
        this.setState(
            {
                showModal: false
            }
        );
    }

    distance(x1, y1, x2, y2) {
        return Math.sqrt(Math.pow(x1 - x2, 2) + Math.pow(y1 - y2, 2));
    }

    pixelRatioCenter() {
        let _ratio = 1;
        let zoom = Math.round(this.state.zoom);

        this.props.config.pixelRatioCenter.forEach(ratio => {
            if (ratio.range[0] <= zoom && zoom <= ratio.range[1]) {
                _ratio = ratio.ratio;
            }
        });

        return _ratio;
    }

    // 
    // Init new leap motion interaction
    //
    isGripped(hand) {
        return hand.grabStrength === 1.0;
    }
    move(frame) {
        var e = this;
        if(frame.valid && frame.gestures.length > 0){
            frame.gestures.forEach(function(gesture){
                if(gesture.type === "circle") {
                    e.zoom(frame, gesture);
                }
            });
        }
        // if there is one hand grabbing...
        if(frame.hands.length > 0 && this.isGripped(frame.hands[0])) {
          var leftHand = frame.hands[0];
          var rightHand = frame.hands.length > 1 ? frame.hands[1] : undefined;
          var separation;
          // If there was no previous closed position, capture it and exit
          if(this.leftHandPrev == null) {
            this.leftHandPrev = leftHand;
            return;
          }
          // if there is a right hand and its gripped...
          if(rightHand) {
            if(this.isGripped(rightHand)) {
              separation = Math.sqrt(
                                Math.pow(rightHand.stabilizedPalmPosition[0] - leftHand.stabilizedPalmPosition[0], 2) + 
                                Math.pow(rightHand.stabilizedPalmPosition[1] - leftHand.stabilizedPalmPosition[1], 2)
                              );
              if(this.separationStart == null) {
                this.separationStart = separation;
                return;
              }
    
              // Calculate if we need to change the zoom level
              var currentZoom = this.olmap.getView().getZoom();
              if(currentZoom > 1 && separation < (this.separationStart / this.state.separationScaling) ) {
                this.olmap.getView().setZoom(currentZoom - 1);
                this.separationStart = separation;
              } else if( currentZoom < this.state.maxZoom && separation > (this.state.separationScaling * this.separationStart) ) {
                this.olmap.getView().setZoom(currentZoom + 1);
                this.separationStart = separation;
              }
            // If the right hand is not gripped...
            } else if(this.separationStart != null) {
              this.separationStart = null;
            }
    
          }
          // Calculate how much the hand moved
          var dX = this.leftHandPrev.stabilizedPalmPosition[0] - leftHand.stabilizedPalmPosition[0];
          var dY = this.leftHandPrev.stabilizedPalmPosition[1] - leftHand.stabilizedPalmPosition[1];
          var center = transform(this.olmap.getView().getCenter(), 'EPSG:3857', 'EPSG:4326');
          var scaling = 4.0 / Math.pow(2, this.olmap.getView().getZoom() - 1);
          var newLat = center[1] + dY * scaling;
          var newLng = center[0] + dX * scaling;
          var newCenter = transform([newLng,newLat], 'EPSG:4326', 'EPSG:3857');
          this.olmap.getView().setCenter(newCenter);
          this.leftHandPrev = leftHand;
        } else {
          // If the left hand is not in a grab position, clear the last hand position
          if(frame.hands.length > 0 && !this.isGripped(frame.hands[0]) && this.leftHandPrev != null) {
            this.leftHandPrev = null;
          }
          // if the right hand is not in a grab position, clear the separation
          if(frame.hands.length > 1 && !this.isGripped(frame.hands[1]) && this.separationStart != null) {
            this.separationStart = null;
          }
        }
    }

    // Zoom based on one index finger, Clockwise
    isClockwise(frame, gesture) {
        var clockwise = false;
        var pointableID = gesture.pointableIds[0];
        var direction = frame.pointable(pointableID).direction;
        var dotProduct = Leap.vec3.dot(direction, gesture.normal);
        if (dotProduct  >  0) clockwise = true;
        return clockwise;
    }
    // Zoom based on one index finger
    zoom(frame, circleGesture) {
        if(circleGesture.pointableIds.length === 1 &&
           frame.pointable(circleGesture.pointableIds[0]).type === 1) {
            switch(circleGesture.state) {
                case "start":
                    this.zoomLevelAtCircleStart = this.olmap.getView().getZoom();
                    break;
                case "update":
                    // figure out if we need to change the zoom level;
                    var zoomChange = Math.floor(circleGesture.progress);
                    var currentZoom = this.olmap.getView().getZoom();
                    var zoomDirection = this.isClockwise(frame, circleGesture) ? zoomChange : - zoomChange;
                    if(this.zoomLevelAtCircleStart + zoomDirection !== currentZoom) {
                        var newZoom = this.zoomLevelAtCircleStart + zoomDirection;
                        if(newZoom >= 0 && newZoom <= this.state.maxZoom) {
                            this.olmap.getView().setZoom(newZoom);
                        }
                    }
                    break;
                case "stop":
                    this.zoomLevelAtCircleStart = null;
                    break;
                default:
            }
        }
    }
    // 
    // End of new leap motion interaction
    //

    //
    // Start of deprecated leap motion interaction
    //
    handsOpen(hands) {
        let isOpen = true;

        hands.forEach(hand => {
            if (isOpen) {
                const position = hand.palmPosition;

                hand.fingers.forEach(finger => {
                    const _position = finger.dipPosition;

                    if (isOpen && this.distance(position[0], position[2], _position[0], _position[2]) < this.props.config.distanceHandOpen) {
                        isOpen = false;
                    }
                });
            }
        });

        return isOpen;
    }
    checkHands(frame) {
        this.oldFrame = this.latestFrame;
        this.latestFrame = frame;
        if (this.oldFrame && this.latestFrame) {
            if (this.oldFrame.hands.length !== this.latestFrame.hands.length) {
                if (this._handMode) {
                    this._handMode = false;

                    setTimeout(() => {
                        this._handMode = true;
                        this.handMode = this.latestFrame.hands.length;
                    }, 100);
                }
            }
        }
        if (this.oldFrame && this.latestFrame && this.oldFrame.hands.length === 2 && this.latestFrame.hands.length === 2 && this.handMode === 2) {
            if (this.handsOpen(this.oldFrame.hands) && this.handsOpen(this.latestFrame.hands)) {
                const oldFramePosition_1 = this.oldFrame.hands[0].palmPosition;
                const oldFramePosition_2 = this.oldFrame.hands[1].palmPosition;
                const distance_1 = this.distance(oldFramePosition_1[0], oldFramePosition_1[1], oldFramePosition_2[0], oldFramePosition_2[1]);
                const latestFramePosition_1 = this.latestFrame.hands[0].palmPosition;
                const latestFramePosition_2 = this.latestFrame.hands[1].palmPosition;
                const distance_2 = this.distance(latestFramePosition_1[0], latestFramePosition_1[1], latestFramePosition_2[0], latestFramePosition_2[1]);
                let zoom = this.state.zoom - ((distance_1 - distance_2) * this.props.config.pixelRatioZoom);

                if (zoom < this.props.config.minZoom) {
                    zoom = this.props.config.minZoom;
                }

                if (this.props.config.maxZoom < zoom) {
                    zoom = this.props.config.maxZoom;
                }

                this.setState(
                    {
                        zoom: zoom
                    },
                    () => {
                        try {
                            // this.state.zoomAudio.play()
                        } catch(_) {}
                    }
                );
            }
        } else {
            if (this.oldFrame && this.latestFrame && this.oldFrame.hands.length === 1 && this.latestFrame.hands.length === 1 && this.handMode === 1) {
                let oldFrameHand = this.oldFrame.hands[0];
                let latestFrameHand = this.latestFrame.hands[0];

                if (oldFrameHand.type !== latestFrameHand.type && this.latestFrame.hands.length > 1) {
                    this.latestFrame.hands.forEach(hand => {
                        if (hand.type === oldFrameHand.type) {
                            latestFrameHand = hand;
                        }
                    });
                }

                if (this.handsOpen([latestFrameHand]) && oldFrameHand.type === latestFrameHand.type) {
                    const position_1 = oldFrameHand.palmPosition;
                    const position_2 = latestFrameHand.palmPosition;
                    let center = this.state.center;
                    let pixelRatioCenter = this.pixelRatioCenter();
                    let zoomChange = Math.abs(position_1[1] - position_2[1]);
                    if (zoomChange >= this.props.config.zoomChange) {
                        let zoom = position_1[1] < position_2[1] ? this.state.zoom - this.props.config.zoomThreshold : this.state.zoom + this.props.config.zoomThreshold;
                        if (zoom < this.props.config.minZoom) {
                            zoom = this.props.config.minZoom;
                        }
                        if (this.props.config.maxZoom < zoom) {
                            zoom = this.props.config.maxZoom;
                        }
                        this.setState({zoom});
                    } else {
                        let c0Change = Math.abs(position_1[0] - position_2[0]);
                        let c2Change = Math.abs(position_1[2] - position_2[2]);
                        if (c0Change >= this.props.config.centerChange || c2Change >= this.props.config.centerChange) {
                            let newCenter = [
                                center[0] + ((position_1[0] - position_2[0]) * pixelRatioCenter),
                                center[1] - ((position_1[2] - position_2[2]) * pixelRatioCenter)
                            ];
                            this.setState(
                                {
                                    center: newCenter
                                }
                            );
                            if (this.state.centerExtent) {
                                if (!this.state.centerExtent.getGeometry().intersectsCoordinate(newCenter)) {
                                    center = this.state.center;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    reMap (value,fromOne, toOne, fromTwo, toTwo) {
        return (value - fromOne) / (toOne - fromOne) * (toTwo - fromTwo) + fromTwo;
    }
    //
    // End deprecated leap motion interaction
    //
    
    componentDidUpdate(prevProps, prevState) {
        if (this.props.itemsChange !== prevProps.itemsChange) {
            if (this.props.itemsChange.indexOf('Search - ') === -1) {
                if (this.props.typeInteraction === 'search') {
                    this.setLayers();
                } else {
                    this.drawFeatures();

                    if (this.props.enableSearch && this.props.reset.indexOf('Show - ') === -1) {
                        this.props.showSearch();
                    }
                }
            }

            this.areaInfo.forEach((_, key) => {
                const find = this.props.items.find(item => item.id === key);

                if (!find) {
                    this.areaInfo.set(key, undefined);
                }
            });
        }

        if (this.props.changeLayers !== prevProps.changeLayers) {
            this.setLayers();
        }

        if (this.props.reset !== prevProps.reset && this.props.reset.indexOf('Hidden - ') === -1) {
            this.setState(
                {
                    center: transform(this.props.config.center, 'EPSG:4326', 'EPSG:3857'),
                    zoom: this.props.config.zoom,
                }
            );

            this.olmap.getView().setRotation(0);
        }
    }

    componentDidMount() {
        this.setLayers();
        this.olmap.setTarget('map');
        this.toggleLayerControl();

        // Listen to map changes
        this.olmap.on("moveend", () => {
            let center = this.olmap.getView().getCenter();
            let zoom = this.olmap.getView().getZoom();

            if (this.state.centerExtent) {
                if (!this.state.centerExtent.getGeometry().intersectsCoordinate(center)) {
                    center = this.state.center;
                }
            }

            if (this.state.zoom !== zoom) {
                try {
                    // this.state.zoomAudio.play()
                } catch(_) {}
            }

            if (this.props.config.minZoom <= zoom && zoom <= this.props.config.maxZoom) {
                this.setState({ center, zoom });
            } else {
                if (zoom < this.props.config.minZoom) {
                    zoom = this.props.config.minZoom;
                }

                if (this.props.config.maxZoom < zoom) {
                    zoom = this.props.config.maxZoom;
                }

                this.setState({ center, zoom });
            }
        });

        this.olmap.on('singleclick', (evt) => this.checkPosition(evt));
        // this.LeepController = Leap.loop({ enableGestures: true }, (frame) => this.checkHands(frame));
        this.LeepController = Leap.loop({ enableGestures: true }, (frame) => this.move(frame));
        this.LeepController.connect();
        this.handMode = -1;
        this._handMode = true;
    }

    render() {
        this.updateMap();
        let { t } = this.props;

        return (
            <div className={'map-box' + ((this.state.loading || this.state.loadingData) ? ' loading' : '')}>
                <div id="map" className={this.props.className} style={{ width: "100%", height: "100%" }}></div>
                <Animated 
                    className="d-flex justify-content-center align-items-center" 
                    animationIn="fadeIn" 
                    animationOut="fadeOut" 
                    animationInDuration={50} 
                    animationOutDuration={50} 
                    isVisible={(this.state.loading || this.state.loadingData) ? true : false}
                >
                    <div className={'text-center rotation ' + this.props.orientation}>
                        <div className="rotate">
                            <BSpinner type="grow">{t('Loading')}</BSpinner>
                            <div>
                                {
                                    this.state.loadingData &&
                                    t('Loading information')
                                }
                                {
                                    !this.state.loadingData &&
                                    t('Reviewing information')
                                }
                            </div>
                        </div>
                    </div>
                </Animated>
                <Filters 
                    settings={this.props.filters} 
                    orientation={this.props.orientation} 
                    show={this.props.showFilters} 
                    setShowFilters={(state) => this.props.setShowFilters(state)} 
                    applyFilters={(cqlFilter, query) => this.applyFilters(cqlFilter, query)} 
                    resetFilters={this.props.resetFilters}
                />
                <Modal isOpen={this.state.showModal} toggle={() => this.closeModal()} centered={true} wrapClassName={'rotation ' + this.props.orientation} modalClassName="rotate">
                    <ModalHeader>{this.props.app}</ModalHeader>
                    <ModalBody>
                        {this.state.modalMsg}
                    </ModalBody>
                    <ModalFooter className="modal-actions">
                        <Button color="secondary" onClick={() => this.closeModal()}>
                            {t('Accept')}
                        </Button>
                    </ModalFooter>
                </Modal>
            </div>
        );
    }
}

export default translate('ViewMap')(ViewMap);
