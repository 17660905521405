import React from 'react';
import { Email, Item} from 'react-html-email';

export default function contentEmail({name, children}) {
    return (
        <Email title='contentEmail'>
            <Item>
                {children}
            </Item>
        </Email>
    )
};