import React, { Component } from 'react';
import { translate } from 'react-translate';

import closeWhite from './images/close-white.png';
import addWhite from './images/add-white.png';

class Item extends Component {
    constructor(props) {
        super(props);

        this.reposition = null;
    }

    close() {
        this.props.removeItem(this.props.id);
    }

    showSearch() {
        this.props.showSearch(this.refs.add.getBoundingClientRect());
    }

    componentDidMount() {

    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.orientation !== this.props.orientation && this.props.mode === "add") {
            clearTimeout(this.reposition);
            this.reposition = setTimeout(() => this.props.showSearch(this.refs.add.getBoundingClientRect(), true), 301);
        }
    }

    render() {
        let { t } = this.props;
        let name = this.props.information.name;
        let info = this.props.information.info;

        if (info === undefined) {
            const data = name.split('-', 2);

            name = data[0].trim();
            info = data[1] ? data[1].trim() : '';
        }

        if (this.props.mode === 'add') {
            return (
                <div className="item-container rotate">
                    <button className="item add" onClick={() => this.showSearch()} ref="add">
                        <div className="name">
                            {name}
                        </div>
                        <div className="info">
                            {info}
                        </div>
                        <div className="icon">
                            <img src={addWhite} className="img-fluid mx-auto d-block" alt={t('Icon')} />
                        </div>
                    </button>
                </div>
            );
        }

        return (
            <div className="item-container rotate">
                <div className="item" style={{ backgroundColor: this.props.settings.color }}>
                    <div className="name">
                        {name}
                    </div>
                    <div className="info">
                        {info}
                    </div>
                    <div className="icon" style={{ backgroundColor: this.props.settings.icon }}>
                        <img src={process.env.PUBLIC_URL + this.props.icon} className="img-fluid mx-auto d-block" alt={t('Icon')} />
                    </div>
                    <button type="button" ref="closeItem" className="action small" onClick={() => this.close()}>
                        <span className="content">
                            <span className="line">
                                <span className="circle">
                                    <img src={closeWhite} className="img-fluid mx-auto d-block" alt={t('Close icon')} />
                                </span>
                            </span>
                        </span>
                        <span className="sr-only">{t('Close')}</span>
                    </button>
                </div>
            </div>
        );
    }
}

export default translate('Item')(Item);
