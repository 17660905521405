import React, { Component } from 'react';
import { translate } from 'react-translate';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Animated } from "react-animated-css";
import { Container, Row, Col, Form, Input, FormGroup, ButtonGroup, Button } from 'reactstrap';

import { faCaretLeft, faCaretRight } from '@fortawesome/free-solid-svg-icons';
import logoVertical from './images/logo_v.png';

class Web extends Component {
    constructor(props) {
        super(props);

        this.state = {
            show: false,
            animationOut: 500,
            titlePage: props.settings.title,
            urlPage: props.settings.url,
            url: props.settings.url,
            history: [props.settings.url],
            index: 0,
            key: 0
        }
    }

    componentDidMount() {
        this.setState(
            {
                show: true
            }
        );
    }

    closeWeb() {
        this.setState(
            {
                show: false
            },
            () => setTimeout(() => {
                this.props.onClose();
            }, this.state.animationOut + 1)
        );
    }

    urlChange(event) {
        this.setState(
            {
                url: event.target.value
            }
        );
    }

    submit(event) {
        if (this.state.url.trim() !== '') {
            const baseUrl = new URL(window.location);
            let history = this.state.history;
            let url = '';

            try {
                url = new URL(this.state.url);
            } catch(_) {
                url = new URL(baseUrl.protocol + '//' + this.state.url);
            }

            if (baseUrl.host === url.host) {
                this.setState(
                    {
                        url: history[this.state.index]
                    }
                );
            } else {
                if (history[this.state.index] !== url.href) {
                    history = history.slice(0, this.state.index + 1);
                    history.push(url.href);

                    this.setState(
                        {
                            urlPage: url.href,
                            url: url.href,
                            history: history,
                            index: history.length - 1,
                            key: history.length - 1,
                        }
                    );
                } else {
                    this.setState(
                        {
                            key: this.state.index + '-' + Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15)
                        }
                    );
                }
            }
        }

        event.preventDefault();
    }

    loadIframe() {
        let text = (new URL(this.refs.iframe.src)).hostname;

        if (this.refs.iframe.contentDocument) {
            text = this.refs.iframe.contentDocument.title;
        }

        this.setState(
            {
                titlePage: text
            }
        );
    }

    backPage() {
        this.setState(
            {
                url: this.state.history[this.state.index - 1],
                urlPage: this.state.history[this.state.index - 1],
                index: this.state.index - 1,
                key: this.state.index - 1,
            }
        );
    }

    forwardPage() {
        this.setState(
            {
                url: this.state.history[this.state.index + 1],
                urlPage: this.state.history[this.state.index + 1],
                index: this.state.index + 1,
                key:this.state.index + 1,
            }
        );
    }

    render() {
        let { t } = this.props;

        return (
            <Animated 
                className="web-box d-flex justify-content-center align-items-center" 
                animationIn="fadeIn" 
                animationOut="fadeOut" 
                animationInDuration={500} 
                animationOutDuration={this.state.animationOut} 
                isVisible={this.state.show}
            >
                <div className="elements-container">
                    <Container fluid>
                        <Row>
                            <Col xs="5" sm="4" md="2" className="text-center">
                                <button className="back" onClick={() => this.closeWeb()}>
                                    <span className="icon">
                                        <img src={logoVertical} className="img-fluid mx-auto d-block" alt={this.props.textLogo} />
                                    </span>
                                    <span className="text">
                                        {t('Back')}
                                    </span>
                                </button>
                            </Col>
                            <Col xs="7" sm="8" md="10">
                                <div className="navigator d-flex flex-column">
                                    <div className="tabs">
                                        <div className="tab">
                                            {this.state.titlePage}
                                        </div>
                                    </div>
                                    <div className="navigation">
                                        <Form onSubmit={(event) => this.submit(event)}>
                                            <Row form>
                                                <Col xs="12" sm="3" md="2" lg="2" xl="1" className="text-right">
                                                    <ButtonGroup>
                                                        <Button 
                                                            color="link" 
                                                            size="lg" 
                                                            type="button" 
                                                            onClick={() => this.backPage()} 
                                                            className={this.state.index <= 0 ? 'disabled' : ''}
                                                        >
                                                            <FontAwesomeIcon icon={faCaretLeft} size="2x" />
                                                            <span className="sr-only">{t('Go back one page')}</span>
                                                        </Button>
                                                        <Button 
                                                            color="link" 
                                                            size="lg" 
                                                            type="button" 
                                                            onClick={() => this.forwardPage()}
                                                            className={this.state.index === (this.state.history.length - 1) ? 'disabled' : ''}
                                                        >
                                                            <FontAwesomeIcon icon={faCaretRight} size="2x" />
                                                            <span className="sr-only">{t('Forward one page')}</span>
                                                        </Button>
                                                    </ButtonGroup>
                                                </Col>
                                                <Col xs="12" sm="8" md="9" lg="9" xl="10" className="d-flex align-items-center">
                                                    <FormGroup>
                                                        <Input type="url" required name="pageUrl" bsSize="sm" value={this.state.url} onChange={(event) => this.urlChange(event)} placeholder="https://"/>
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                        </Form>
                                    </div>
                                    <div className="content">
                                        <iframe key={this.state.key} src={this.state.urlPage} title={this.state.titlePage} ref="iframe" onLoad={() => this.loadIframe()}></iframe>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </Animated>
        );
    }
}

export default translate('Web')(Web);