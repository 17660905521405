import React, { Component } from 'react';
import Keyboard from 'react-simple-keyboard';
import html2canvas from 'html2canvas';
import { translate } from 'react-translate';
import { Collapse, Form, Input, InputGroup, InputGroupAddon, Button, Spinner as BSpinner, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { renderEmail } from 'react-html-email';
import Email from './Email';

import 'react-simple-keyboard/build/css/index.css';
import { faShareAlt, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import closeWhite from './images/close-white.png';

class Screenshot extends Component {
    constructor(props) {
        super(props);

        this.state = {
            show: false,
            image: null,
            animationOut: 0,
            showSend: false,
            showLoading: false,
            email: '',
            modalMsg: '',
            showModal: false,
            showKeyboard: false,
            disabledSend: true,
            layoutName: 'default'
        }
    }

    componentDidMount() {
        this.screenshot();
    }

    screenshot() {
        document.getElementById('root').className = "screenshot";

        html2canvas(document.querySelector("#root")).then(canvas => {
            this.setState(
                {
                    show: true,
                    image:  canvas.toDataURL(),
                    animationOut: 500
                },
                () => {
                    document.getElementById('root').className = "";
                    this.props.showSearch();
                }
            );
        });
    }

    closeScreenshot() {
        this.setState(
            {
                show: false
            },
            () => setTimeout(() => {
                this.props.onClose();
            }, this.state.animationOut + 1)
        );
    }

    share() {
        if (this.props.virtualKeyboard) {
            this.setState(
                {
                    showKeyboard: true
                }
            );
        } else {
            this.setState(
                {
                    showSend: true
                }
            );
        }
    }

    emailChange(event, virtualKeyboard) {
        const value = virtualKeyboard ? event.trim() : event.target.value;
        let disabled = true;

        if (/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(value)) {
            disabled = false;
        }

        this.setState(
            {
                email: value,
                disabledSend: disabled
            }
        );
    }

    submit(event) {
        if (this.state.email) {
            const messageHtml =  renderEmail(
                <Email>
                    {this.props.settings.sendEmail.message}
                </Email>
            );

            this.setState(
                {
                    showLoading: true
                }
            );

            window.Email.send({
                    SecureToken: this.props.settings.sendEmail.hash,
                    From: this.props.settings.sendEmail.from,
                    To: this.state.email,
                    Subject: this.props.settings.sendEmail.subject,
                    Body: messageHtml,
                    Attachments: [{
                        name : "screenshot.png",
                        data: this.state.image
                    }]
                })
                .then((message) => {
                    if(message.toUpperCase() === 'OK') {
                        this.setState(
                            {
                                showLoading: false,
                                showSend: this.props.virtualKeyboard ? false : true,
                                email: '',
                                modalMsg: this.props.settings.sendEmail.success,
                                showModal: true,
                                showKeyboard: false
                            }
                        );
                    } else {
                        this.setState(
                            {
                                showLoading: false,
                                showSend: this.props.virtualKeyboard ? false : true,
                                modalMsg: this.props.settings.sendEmail.error,
                                showModal: true
                            }
                        );
                    }
                })
                .catch((error) => {
                    console.log(error);
                    this.setState(
                        {
                            showLoading: false,
                            showSend: this.props.virtualKeyboard ? false : true,
                            modalMsg: this.props.settings.sendEmail.error,
                            showModal: true
                        }
                    );
                });

            /*axios({
                    method: 'POST',
                    url: this.props.settings.sendEmail.url,
                    data: {
                        subject: this.props.settings.sendEmail.subject,
                        email: this.state.email,
                        messageHtml: messageHtml
                    }
                })
                .then((response) => {
                    if (response.data.msg === 'success') {
                        this.setState(
                            {
                                showLoading: false,
                                showSend: true,
                                email: '',
                                modalMsg: this.props.settings.sendEmail.success,
                                showModal: true
                            }
                        );
                    } else {
                        this.setState(
                            {
                                showLoading: false,
                                showSend: true,
                                modalMsg: this.props.settings.sendEmail.error,
                                showModal: true
                            }
                        );
                    }
                })
                .catch((error) => {
                    console.log(error);
                    this.setState(
                        {
                            showLoading: false,
                            showSend: true,
                            modalMsg: this.props.settings.sendEmail.error,
                            showModal: true
                        }
                    );
                });*/
        }

        event.preventDefault();
    }

    closeModal() {
        this.setState(
            {
                showModal: false
            }
        );
    }

    closeKeyboard() {
        this.setState(
            {
                showKeyboard: false,
                email: ''
            }
        );
    }

    onKeyPress(button) {
        if (button === '{shift}' || button === '{lock}') this.handleShift();
    };

    handleShift() {
        const layoutName = this.state.layoutName;
    
        this.setState({
            layoutName: layoutName === 'default' ? 'shift' : 'default'
        });
    };

    render() {
        let { t } = this.props;

        return (
            <div className="screenshot-box">
                <Modal className="screenshot-container" isOpen={this.state.show} toggle={() => this.closeScreenshot()} size="xl" centered={true} wrapClassName={'rotation ' + this.props.orientation} modalClassName="rotate">
                    <ModalBody className="elements-container">
                        <button type="button" ref="closeScreenshot" className="action small red" onClick={() => this.closeScreenshot()}>
                            <span className="content">
                                <span className="line">
                                    <span className="circle">
                                        <img src={closeWhite} className="img-fluid mx-auto d-block" alt={t('Close icon')} />
                                    </span>
                                </span>
                            </span>
                            <span className="sr-only">{t('Close')}</span>
                        </button>
                        {
                            this.state.image &&
                            <div className="img-container">
                                <img className="img-fluid mx-auto d-block" src={this.state.image} alt={t('Screenshot image')} />
                            </div>
                        }
                        <Modal className="screenshot-keyboard-container" isOpen={this.state.showKeyboard} centered={true} backdrop="static" size="lg" wrapClassName={'rotation ' + this.props.orientation} modalClassName="rotate">
                            <ModalBody>
                                <button type="button" className="action small red" onClick={() => this.closeKeyboard()}>
                                    <span className="content">
                                        <span className="line">
                                            <span className="circle">
                                                <img src={closeWhite} className="img-fluid mx-auto d-block" alt={t('Close icon')} />
                                            </span>
                                        </span>
                                    </span>
                                    <span className="sr-only">{t('Close')}</span>
                                </button>
                                <Form onSubmit={(event) => this.submit(event)}>
                                    <InputGroup>
                                        <Input 
                                            type="email" 
                                            value={this.state.email} 
                                            onChange={(event) => this.emailChange(event)} 
                                            placeholder={t('Email')} 
                                            autoComplete="false" 
                                            disabled={this.state.showLoading} 
                                            required
                                        />
                                        <InputGroupAddon addonType="append">
                                            <Button color="primary" disabled={this.state.showLoading || this.state.disabledSend}>
                                                {
                                                    this.state.showLoading &&
                                                    <span>
                                                        <BSpinner tag="span" size="sm" type="grow">{t('Sending')}</BSpinner>
                                                        <span aria-hidden="true" className="text">{t('Sending')}</span>
                                                    </span>
                                                }
                                                {
                                                    !this.state.showLoading &&
                                                    <span>
                                                        <FontAwesomeIcon icon={faEnvelope} /> {t('Send')}
                                                    </span>
                                                }
                                            </Button>
                                        </InputGroupAddon>
                                    </InputGroup>
                                </Form>
                                <div className="keyboard">
                                    <Keyboard 
                                        onChange={(input) => this.emailChange(input, true)} 
                                        layoutName={this.state.layoutName} 
                                        onKeyPress={(button) => this.onKeyPress(button)}
                                    />
                                </div>
                            </ModalBody>
                        </Modal>
                    </ModalBody>
                    <ModalFooter className="elements-actions modal-actions">
                        <Collapse isOpen={this.state.showSend}>
                            <Form onSubmit={(event) => this.submit(event)}>
                                <InputGroup>
                                    <Input 
                                        type="email" 
                                        value={this.state.email} 
                                        onChange={(event) => this.emailChange(event)} 
                                        placeholder={t('Email')} 
                                        autoComplete="false" 
                                        disabled={this.state.showLoading} 
                                        required
                                    />
                                    <InputGroupAddon addonType="append">
                                        <Button color="primary" disabled={this.state.showLoading || this.state.disabledSend}>
                                            {
                                                this.state.showLoading &&
                                                <span>
                                                    <BSpinner tag="span" size="sm" type="grow">{t('Sending')}</BSpinner>
                                                    <span aria-hidden="true" className="text">{t('Sending')}</span>
                                                </span>
                                            }
                                            {
                                                !this.state.showLoading &&
                                                <span>
                                                    <FontAwesomeIcon icon={faEnvelope} /> {t('Send')}
                                                </span>
                                            }
                                        </Button>
                                    </InputGroupAddon>
                                </InputGroup>
                            </Form>
                        </Collapse>
                        <Collapse isOpen={!this.state.showSend}>
                            <Button color="primary" type="button" onClick={() => this.share()}>
                                <FontAwesomeIcon icon={faShareAlt} /> {t('Share')}
                            </Button>
                        </Collapse>
                    </ModalFooter>
                </Modal>
                <Modal isOpen={this.state.showModal} toggle={() => this.closeModal()} centered={true} wrapClassName={'rotation ' + this.props.orientation} modalClassName="rotate">
                    <ModalHeader>{this.props.settings.sendEmail.subject}</ModalHeader>
                    <ModalBody>
                        {this.state.modalMsg}
                    </ModalBody>
                    <ModalFooter className="modal-actions">
                        <Button color="secondary" onClick={() => this.closeModal()}>
                            {t('Accept')}
                        </Button>
                    </ModalFooter>
                </Modal>
            </div>
        );
    }
}

export default translate('Screenshot')(Screenshot);