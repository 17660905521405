import React, { Component } from 'react';
import { translate } from 'react-translate';
import { Button } from 'reactstrap';
import { Animated } from "react-animated-css";

import MenuSelectAudio from './sounds/menu-select.wav';

class Mode extends Component {
    constructor(props) {
        super(props);

        this.state = {
            backgroundColor: {
                backgroundColor: props.backgroundColor[0],
                backgroundImage: 'linear-gradient(135deg,' + props.backgroundColor[0] + ' 0%, ' + props.backgroundColor[1] + ' 100%)'
            },
            size: {
                circle: 200,
                point: 24
            },
            lineWidth: (5 * props.sizeBase / 16) * 2,
            position: {
                marginTop: -12,
                marginRight: -10
            },
            showInfo: false,
            animationOut: 0
        }

        this.menuSelectAudio = new Audio(MenuSelectAudio);
    }

    componentDidMount() {
        this.setState(
            {
                size: {
                    circle: this.refs.mode.offsetWidth * 2,
                    point: (this.refs.mode.offsetWidth - this.refs.button.offsetWidth) * 0.75
                }
            },
            () => {
                this.drawCircles();
            }
        );
    }

    drawCircles() {
        const ctxC = this.refs.circle.getContext('2d');
        const centerC = this.state.size.circle / 2;
        const radiusC = (this.state.size.circle / 2)- 5;
        const gradent = ctxC.createLinearGradient(0, 0, 300, 300);

        gradent.addColorStop(0, this.props.backgroundColor[0]);
        gradent.addColorStop(1, this.props.backgroundColor[1]);

        ctxC.beginPath();
        ctxC.arc(centerC, centerC, radiusC, 0, 2 * Math.PI, false);
        ctxC.fillStyle = gradent;
        ctxC.fill();
        ctxC.closePath();
        ctxC.beginPath();
        ctxC.globalCompositeOperation = 'destination-out';
        ctxC.arc(centerC, centerC, radiusC - this.state.lineWidth, 0, 2 * Math.PI, false);
        ctxC.fill();
        ctxC.closePath();

        const ctxP = this.refs.point.getContext('2d');
        const centerP = this.state.size.point / 2;
        const radiusP = this.state.size.point / 2 - 1;

        ctxP.beginPath();
        ctxP.arc(centerP, centerP, radiusP, 0, 2 * Math.PI, false);
        ctxP.fillStyle = gradent;
        ctxP.fill();
        ctxP.closePath();
        ctxP.beginPath();
        ctxP.arc(centerP, centerP, radiusP - (this.state.lineWidth / 3), 0, 2 * Math.PI, false);
        ctxP.fillStyle = 'white';
        ctxP.fill();
        ctxP.closePath();
        ctxP.beginPath();
        ctxP.arc(centerP, centerP, radiusP - (this.state.lineWidth / 3) * 2, 0, 2 * Math.PI, false);
        ctxP.fillStyle = gradent;
        ctxP.fill();
        ctxP.closePath();
        ctxP.beginPath();
        ctxP.arc(centerP, centerP, radiusP - (this.state.lineWidth / 3) * 3, 0, 2 * Math.PI, false);
        ctxP.fillStyle = 'white';
        ctxP.fill();
        ctxP.closePath();

        this.setState(
            {
                position: {
                    marginTop: -1 * (this.state.size.point / 2),
                    marginRight: -1 * ((this.state.size.point / 2) - (2 * this.state.size.point / 24))
                }
            }
        );
    }

    setActive() {
        try {
            this.menuSelectAudio.play();
        } catch(_) {}

        this.setState(
            {
                showInfo: !this.state.showInfo,
                animationOut: 500
            },
            () => {
                if (this.props.active.id !== this.props.id) {
                    this.props.onChangeMode(this.props.id, this.refs.mode.getBoundingClientRect());
                } else {
                    this.props.loadGeneralData();
                    this.props.showSearch();
                }
            }
        );
    }

    close() {
        this.setState(
            {
                showInfo: false
            }
        );
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.active.id !== prevProps.active.id) {
            this.setState(
                {
                    showInfo: true
                }
            );
        }
    }

    render() {
        let { t } = this.props;
        let active = this.props.active.id === this.props.id;

        return (
            <div className={'disable-doubletap-to-zoom mode rotation ' + this.props.orientation + (active ? ' active' : '')} ref="mode">
                <div className="border-content">
                    <canvas className="circle rotate" ref="circle" height={this.state.size.circle} width={this.state.size.circle}></canvas>
                    <canvas className="point" ref="point" height={this.state.size.point} width={this.state.size.point} style={this.state.position}></canvas>
                </div>
                <div className="button-content rotate">
                    <button id={'btn-' + this.props.id + '-mode'} type="button" ref="button" style={this.state.backgroundColor} onClick={() => this.setActive()}>
                        <span className="icon">
                            <img src={process.env.PUBLIC_URL + this.props.icon} className="img-fluid mx-auto d-block" alt="icono" />
                        </span>
                        <span className="label">{this.props.label}</span>
                    </button>
                </div>
                {
                    this.props.helpText &&
                    <Animated 
                        className={'disable-doubletap-to-zoom help-box ' + this.props.orientation} 
                        animationIn="fadeIn" 
                        animationOut="fadeOut" 
                        animationInDuration={500} 
                        animationOutDuration={this.state.animationOut} 
                        isVisible={this.state.showInfo && this.props.active.id === this.props.id ? true : false}
                    >
                        <div className="help-container rotate">
                            <div className="container">
                                <div className="row align-items-center">
                                    <div className="col-2">
                                        <div className="icon">
                                            <img src={process.env.PUBLIC_URL + this.props.helpImage} className="img-fluid mx-auto d-block" alt={t('Help icon')} />
                                        </div>
                                    </div>
                                    <div className="col-6 text-left">
                                        <div className="d-flex justify-content-center">
                                            <div className="text">
                                                {this.props.helpText}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-4">
                                        <Button color="primary" onClick={() => this.close()}>
                                            {t('Agreed')}
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Animated>
                }
            </div>
        );
    }
}

export default translate('Mode')(Mode);
