import React, { Component } from 'react';
import ReactDOMServer from 'react-dom/server';
import { ResponsiveBar } from '@nivo/bar';
import { translate } from 'react-translate';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Alert, Collapse, Form, CustomInput, Spinner as BSpinner } from 'reactstrap';

import { faCog, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import config from './images/config.png';
import rotateWhite from './images/rotate-white.png';

import ToggleAudio from './sounds/collapse.wav';

class Chart extends Component {
    constructor(props) {
        super(props);

        this.state = {
            data: null,
            keys: [],
            attrs: [],
            activeAttrs: new Map(),
            colors: [],
            show: true,
            showConfig: true,
            orientation: props.orientation,
            enableRotation: true,
            customRotation: false,
            contentWidth: 0,
            contentHeight: 0,
            mode: 'object'
        }

        this.faCog = this.encodeSVG(<FontAwesomeIcon icon={faCog} color="transparent" />);
        this.toggleAudio = new Audio(ToggleAudio);
    }

    setAttrs() {
        let activeAttrs = new Map();
        let attrs = Object.keys(this.props.settings.graphic);
        let mode = 'object';

        if (this.props.data && this.props.data[0] && Array.isArray(this.props.data[0].data)) {
            attrs = [];
            mode = 'array';

            this.props.data.forEach(item => {
                item.data.forEach(element => {
                    if (element.variable && !attrs[element.variable]) {
                        attrs.push(element.variable);
                        activeAttrs.set(element.variable, this.state.activeAttrs.get(element.variable) !== undefined ? this.state.activeAttrs.get(element.variable) : true);
                    }
                });
            });
        } else {
            Object.keys(this.props.settings.graphic).forEach(key => {
                activeAttrs.set(key, this.state.activeAttrs.get(key) !== undefined ? this.state.activeAttrs.get(key) : true);
            });
        }

        this.setState(
            {
                attrs: attrs,
                activeAttrs: activeAttrs,
                mode: mode
            },
            () => this.setData()
        );
    }

    setData() {
        let result = [];
        let keys = [];
        let colors = [];

        if (this.props.data === null) {
            return null;
        }

        this.state.activeAttrs.forEach((isChecked, key) => {
            if (isChecked) {
                let dataItem = {
                    name: this.props.settings.graphic[key] ? this.props.settings.graphic[key] : key
                };
                let _keys = [];
                let _colors = [];

                this.props.data.forEach(item => {
                    if (this.state.mode === 'array') {
                        let element = item.data.find(_item => _item.variable === key);

                        _keys.push(item.id);
                        _colors.push(item.color);

                        if (element && element.measure !== undefined) {
                            dataItem[item.id] = parseFloat(element.measure.toString().replace(',', ''));
                        } else {
                            dataItem[item.id] = 0;
                        }
                    } else {
                        if (item.data[key] && item.data[key].value !== undefined) {
                            _keys.push(item.id);
                            _colors.push(item.color);
                            dataItem[item.id] = parseFloat(item.data[key].value.toString().replace(',', ''));
                        }
                    }
                });

                if (Object.keys(dataItem).length > 1) {
                    keys = _keys;
                    colors = _colors;
                    result.push(dataItem);
                }
            }
        });

        this.setState(
            {
                data: result,
                keys: keys,
                colors: colors
            }
        );
    }

    componentDidMount() {
        this.setAttrs();

        this.setState(
            {
                showConfig: false
            },
            () => this.calcSize()
        );
    }

    calcSize() {
        setTimeout(() => {
            if (this.refs.container) {
                this.setState(
                    {
                        contentWidth: this.refs.container.offsetWidth,
                        contentHeight: this.refs.container.offsetHeight
                    },
                    () => {
                        let orientation = this.state.customRotation ? this.state.orientation : this.props.orientation;

                        if (orientation === 'left') {
                            clearTimeout(this.onScroll);

                            this.onScroll = setTimeout(() => {
                                this.scrollLeft();
                            }, 300);
                        }
                    }
                );
            }
        }, 500);
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.itemsChange !== prevProps.itemsChange) {
            if (this.props.itemsChange.indexOf('Search - ') === -1 || this.props.itemsChange.indexOf('Graphics - ') !== -1) {
                this.setAttrs();
            }
        }

        if (prevState.orientation !== this.state.orientation && this.state.orientation === this.props.orientation && this.state.customRotation && prevState.customRotation) {
            this.setState(
                {
                    customRotation: false
                }
            );
        }

        if ((prevState.orientation !== this.state.orientation) || (prevProps.orientation !== this.props.orientation) || (prevProps.size.width !== this.props.size.width)) {
            clearTimeout(this.onCalc);

            this.onCalc = setTimeout(() => {
                this.calcSize();
            }, 100);
        }
    }

    getTextWidth(text, font = '500 ' + this.props.sizeBase + ' sans-serif') {
        const canvas = document.createElement('canvas');
        const context = canvas.getContext('2d');

        context.font = font;

        return context.measureText(text).width;
    }

    breakString(word, maxWidth, hyphenCharacter='-') {
        const characters = word.split('');
        const lines = [];
        let currentLine = '';

        characters.forEach((character, index) => {
            const nextLine = `${currentLine}${character}`;
            const lineWidth = this.getTextWidth(nextLine);
            if (lineWidth >= maxWidth) {
                const currentCharacter = index + 1;
                const isLastLine = characters.length === currentCharacter;
                const hyphenatedNextLine = `${nextLine}${hyphenCharacter}`;
                lines.push(isLastLine ? nextLine : hyphenatedNextLine);
                currentLine = '';
            } else {
                currentLine = nextLine;
            }
        });

        return { hyphenatedStrings: lines, remainingWord: currentLine };
    }

    wrapLabel(label, maxWidth) {
        const words = label.toString().split(' ');
        const completedLines = [];
        let nextLine = '';

        words.forEach((word, index) => {
            const wordLength = this.getTextWidth(`${word} `);
            const nextLineLength = this.getTextWidth(nextLine);

            if (wordLength > maxWidth) {
                const { hyphenatedStrings, remainingWord } = this.breakString(word, maxWidth);

                completedLines.push(nextLine, ...hyphenatedStrings);
                nextLine = remainingWord;
            } else {
                if (nextLineLength + wordLength >= maxWidth) {
                    completedLines.push(nextLine);
                    nextLine = word;
                } else {
                    nextLine = [nextLine, word].filter(Boolean).join(' ');
                }
            }

            const currentWord = index + 1;
            const isLastWord = currentWord === words.length;

            if (isLastWord) {
                completedLines.push(nextLine);
            }
        });

        return completedLines.filter(line => line !== '');
    }

    gridLabel({ id, anchor }) {
        const label = this.wrapLabel(id, 65);

        return (
            <text
                style={{
                    fontSize: this.props.sizeBase,
                    textAnchor: anchor
                }}
            >
                {
                    label.map((word, index) => (
                        <tspan key={index} x={0} dy={index === 0 ? 0 : 14}>
                            {word}
                        </tspan>
                    ))
                }
            </text>
        );
    }

    toggle(event) {
        try {
            this.toggleAudio.play();
        } catch(_) {}

        this.setState(
            {
                show: !this.state.show
            },
            () => this.calcSize()
        );

        event.preventDefault();
    }

    toggleConfig() {
        this.setState(
            {
                showConfig: !this.state.showConfig
            },
            () => this.calcSize()
        );
    }

    inputChange(event) {
        const item = event.target.value;
        const isChecked = event.target.checked;

        this.setState(
            prevState => (
                {
                    activeAttrs: prevState.activeAttrs.set(item, isChecked)
                }
            ),
            () => this.setData()
        );
    }

    encodeSVG(reactElement) {
        return 'data:image/svg+xml,' + escape(ReactDOMServer.renderToStaticMarkup((reactElement)));
    }

    rotation() {
        if (this.state.customRotation) {
            this.props.updateOrientation(this)
        } else {
            this.setState(
                {
                    customRotation: true,
                    orientation: this.props.orientation
                },
                () => this.props.updateOrientation(this)
            );
        }
    }

    getScrollbarWidth() {
        const outer = document.createElement("div");
        outer.style.visibility = "hidden";
        outer.style.width = "100px";
        outer.style.msOverflowStyle = "scrollbar"; // needed for WinJS apps

        document.body.appendChild(outer);

        const widthNoScroll = outer.offsetWidth;
        // force scrollbars
        outer.style.overflow = "scroll";

        // add innerdiv
        const inner = document.createElement("div");
        inner.style.width = "100%";
        outer.appendChild(inner);

        const widthWithScroll = inner.offsetWidth;

        // remove divs
        outer.parentNode.removeChild(outer);

        return widthNoScroll - widthWithScroll;
    }

    scrollLeft() {
        this.refs.graphicBox.scrollLeft = this.state.contentWidth;
    }

    renderTick({ opacity, textAnchor, textBaseline, textX, textY, theme, value, x, y}) {
        return (
            <g
                transform={`translate(${x}, ${y})`}
                style={{ opacity }}
            >
                <text
                    style={{
                        fontSize: this.props.sizeBase,
                        alignmentBaseline: textBaseline,
                        textAnchor: textAnchor,
                        transform: `translate(${textX}px, ${textY}px)`
                    }}
                >
                    {
                        this.wrapLabel(value, 65).map((word, index) => (
                            <tspan key={index} x={0} dy={index === 0 ? 0 : 14}>
                                {word}
                            </tspan>
                        ))
                    }
                </text>
            </g>
        )
    }

    render() {
        let { t } = this.props;
        let orientation = this.state.customRotation ? this.state.orientation : this.props.orientation;

        return (
            <section 
                className={'graphic-box rotation ' + orientation} 
                ref="graphicBox"
                style={{ 
                    height: (orientation === 'left' || orientation === 'right') ? this.props.width + this.getScrollbarWidth() : 'auto',
                    '--rotate-width': -1 * this.state.contentWidth + 'px',
                    '--rotate-height': -1 * this.state.contentHeight + 'px'
                }}
            >
                <div
                    style={{ 
                        width: (orientation === 'left' || orientation === 'right') ? this.state.contentHeight : this.state.contentWidth,
                        height: 2,
                        marginTop: -2
                    }}
                ></div>
                <div 
                    className="rotate" 
                    ref="container" 
                    style={{
                        minHeight: (orientation === 'left' || orientation === 'right') ? this.props.width - 2 : 'auto',
                    }}
                >
                    <div className="text-container">
                        <div className="text">
                            <h2>
                                <FontAwesomeIcon icon={faChevronRight} size="lg" className={this.state.show ? 'active' : ''}/>
                                <a role="button" onClick={(event) => this.toggle(event)} href={'#box-' + this.props.id}>{this.props.title}</a>
                            </h2>
                            {
                                this.props.info &&
                                <div className="info">{this.props.info}</div>
                            }
                            {
                                !this.props.info &&
                                <div className="info"></div>
                            }
                        </div>
                    </div>
                    <button type="button" ref="rotate" className={'action small rotation' + (!this.state.enableRotation ? 'disabled' : '')} onClick={() => this.rotation()} title={t('Rotate')}>
                        <span className="content">
                            <span className="line">
                                <span className="circle">
                                    <img src={rotateWhite} className="img-fluid mx-auto d-block" alt={t('Rotate icon')} />
                                </span>
                            </span>
                        </span>
                        <span className="sr-only">{t('Rotate')}</span>
                    </button>
                    <Collapse 
                        isOpen={this.state.show}
                        className="chart-box"
                        id={'#box-' + this.props.id}
                    >
                        <div className="chart-container" style={{ height: (this.state.data === null || (this.state.data !== null && this.state.data.length > 0)) ? this.props.size.height : 'auto', width: this.props.size.width }}>
                            {
                                this.state.data !== null && this.state.data.length > 0 &&
                                <ResponsiveBar
                                    data={this.state.data}
                                    keys={this.state.keys}
                                    indexBy="name"
                                    maxValue="auto"
                                    mixValue="0.1"
                                    layout="horizontal"
                                    groupMode="grouped"
                                    margin={{ top: 0, right: 100, bottom: 50, left: 150 }}
                                    axisTop={null}
                                    axisRight={null}
                                    axisBottom={{
                                        tickSize: 0,
                                        tickPadding: 5,
                                        tickRotation: 90,
                                        format: "~s"
                                    }}
                                    axisLeft={{
                                        tickSize: 0,
                                        tickPadding: 25,
                                        tickRotation: 0,
                                        renderTick: (props) => this.renderTick(props)
                                    }}
                                    colors={this.state.colors}
                                    enableLabel={false}
                                    blendMode="multiply"
                                    enableGridY={false}
                                    enableGridX={true}
                                    animate={true}
                                    motionStiffness={90}
                                    motionDamping={15}
                                    isInteractive={true} 
                                    tooltip={({ id, value }) => (
                                        <div>
                                            {id}: <strong>{`${Number(value).toLocaleString('en-US')}`}</strong>
                                        </div>
                                    )}
                                />
                            }
                            {
                                this.state.data !== null && this.state.data.length === 0 &&
                                <div className="message d-flex justify-content-center align-items-center">
                                    <Alert color="info">
                                        {this.props.noResults}
                                    </Alert>
                                </div>
                            }
                            {
                                this.state.data === null &&
                                <div className="message d-flex justify-content-center align-items-center">
                                    <div className="text-center">
                                        <BSpinner type="grow">{t('Loading')}</BSpinner>
                                        <div>
                                            {t('Loading')}
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                        <div className="chart-config">
                            {
                                this.state.attrs.length > 0 &&
                                <div className="title">
                                    {
                                        false &&
                                        <FontAwesomeIcon icon={faChevronRight} size="lg" className={this.state.showConfig ? 'active' : ''} />
                                    }
                                    <button type="button" ref="config" className="action small" onClick={() => this.toggleConfig()} style={{ backgroundImage: 'url(' + config + ')' }}>
                                        <span className="content">
                                            <span className="line">
                                                <span className="circle">
                                                    <img src={this.faCog} className="img-fluid mx-auto d-block" alt={t('Settings icon')} />
                                                </span>
                                            </span>
                                        </span>
                                        <span className="sr-only">{t('Settings')}</span>
                                    </button>
                                </div>
                            }
                            <Collapse 
                                isOpen={this.state.showConfig} 
                            >
                                <div ref="form" className="form-container">
                                    <Form>
                                        {
                                            this.state.attrs.map((key, index) => (
                                                <CustomInput 
                                                    key={index} 
                                                    type="checkbox" 
                                                    label={this.props.settings.graphic[key] ? this.props.settings.graphic[key] : key} 
                                                    value={key} 
                                                    name={this.props.id} 
                                                    id={this.props.id + '-' + key} 
                                                    onChange={(e) => this.inputChange(e)}
                                                    checked={this.state.activeAttrs.get(key) === undefined ? false : this.state.activeAttrs.get(key)}
                                                />
                                            ))
                                        }
                                    </Form>
                                </div>
                            </Collapse>
                        </div>
                    </Collapse>
                </div>
            </section>
        );
    }
}

export default translate('Chart')(Chart);