import React, { Component } from 'react';
import Draggable from 'react-draggable';
import Chart from "./Chart";
import { translate } from 'react-translate';
import { Spinner as BSpinner } from 'reactstrap';
import { Animated } from "react-animated-css";

import logo from './images/logo.png';
import logoV from './images/logo_v.png';

class Graphics extends Component {
    constructor(props) {
        super(props);

        this.state = {
            defaultControlledPosition: {
                x: 2 * props.sizeBase,
                y: 2 * props.sizeBase
            },
            controlledPosition: {
                x: 2 * props.sizeBase,
                y: 2 * props.sizeBase
            },
            defaultWidth: this.props.minWidth,
            width: this.props.minWidth,
            chartWidth: this.props.minWidth,
            dragging: false
        }

        this.onResize = null;

        this.updateDimensions = () => {
            clearTimeout(this.onResize);

            this.onResize = setTimeout(() => {
                this.props.onResize(this.state.chartWidth, true);
                this.updateControlledPosition();
            }, 100);
        }
    }

    onControlledDrag(e, position) {
        const {x} = position;
        const resize = this.state.defaultControlledPosition.x - x;
        const width = this.state.defaultWidth + resize;

        if (width > this.props.minWidth && width < window.innerWidth - this.refs.resize.offsetWidth) {
            this.setState(
                {
                    controlledPosition: {
                        x: x,
                        y: this.state.defaultControlledPosition.y
                    },
                    width: width
                }
            );
        } else {
            this.setState(
                {
                    controlledPosition: {
                        x: window.innerWidth - this.refs.resize.offsetWidth - this.refs.graphics.offsetWidth,
                        y: this.state.defaultControlledPosition.y
                    },
                    chartWidth: this.refs.graphics.offsetWidth,
                    dragging: false
                }
            );
        }
    };

    onControlledStart() {
        this.refs.graphics.scrollTop = 0;

        this.setState(
            {
                dragging: true
            }
        );
    }

    onControlledStop(e, position) {
        const {x} = position;
        const resize = this.state.defaultControlledPosition.x - x;
        const width = this.state.defaultWidth + resize;

        if (width > this.props.minWidth) {
            this.setState(
                {
                    chartWidth: width,
                    dragging: false
                }
            );

            this.props.onResize(width, true);
        }
    }

    updateControlledPosition() {
        const x = window.innerWidth - this.refs.resize.offsetWidth - this.refs.graphics.offsetWidth;
        const y = (this.refs.graphics.offsetHeight / 2) - (this.refs.resize.offsetHeight / 2);
        const w = this.props.minWidth;

        this.setState(
            {
                defaultControlledPosition: {
                    x: x,
                    y: y
                },
                controlledPosition: {
                    x: x,
                    y: y
                },
                defaultWidth: (w < this.refs.graphics.offsetWidth ? this.refs.graphics.offsetWidth : w),
                width: (w < this.refs.graphics.offsetWidth ? this.refs.graphics.offsetWidth : w)
            }
        );
    }

    componentDidMount() {
        this.updateControlledPosition();
        this.props.onResize(this.state.chartWidth, false);
        window.addEventListener('resize', this.updateDimensions);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateDimensions);
    }

    getData(index) {
        let result = null;

        this.props.data.forEach(item => {
            if (item.information && item.graphics && item.graphics[index]) {
                if (result === null) {
                    result = [];
                }

                result.push({
                    id: (item.id ? item.id + ' - ' : '') + item.information.name,
                    data: item.graphics[index],
                    color: item.settings.color
                });
            }
        });

        return result;
    }

    render() {
        const {controlledPosition} = this.state;
        let { t } = this.props;

        return (
            <div className="graphics-box">
                <Animated 
                    className="graphics"
                    animationIn="fadeIn" 
                    animationOut="fadeOut" 
                    animationInDuration={500} 
                    animationOutDuration={500} 
                    isVisible={this.props.data.length !== 0 ? true : false} 
                    style={{ width: this.state.width }}
                >
                    <div className={'disable-doubletap-to-zoom logo-box rotation ' + this.props.orientation} ref="logoBox">
                        <img src={logo} className="logo rotate img-fluid mx-auto d-block" alt={this.props.textLogo} ref="logo"/>
                        <img src={logoV} className="logov rotate" alt={this.props.textLogo}/>
                    </div>
                    <div ref="graphics" className={'disable-doubletap-to-zoom content' + (this.state.dragging ? ' dragging' : '')}>
                        {
                            <Animated 
                                className={'message d-flex justify-content-center align-items-center rotation ' + this.props.orientation}
                                animationIn="fadeIn" 
                                animationOut="fadeOut" 
                                animationInDuration={500} 
                                animationOutDuration={500} 
                                isVisible={this.state.dragging ? true : false}
                            >
                                <div className="text-center rotate">
                                    <BSpinner type="grow">{t('Resizing')}</BSpinner>
                                    <div>
                                        {t('Resizing')}
                                    </div>
                                </div>
                            </Animated>
                        }
                        {
                            this.props.data &&
                            this.props.settings.map((setting, index) => (
                                <Animated 
                                    animationIn="fadeIn" 
                                    animationOut="fadeOut" 
                                    animationInDuration={500} 
                                    animationOutDuration={500} 
                                    isVisible={this.state.dragging ? false : true} 
                                    key={index}
                                    className="graphic-animation"
                                >
                                    <Chart 
                                        title={setting.title} 
                                        info={setting.info} 
                                        noResults={setting.noResults} 
                                        id={'chart-' + index}
                                        data={this.getData(index)} 
                                        settings={setting} 
                                        sizeBase={this.props.sizeBase}
                                        size={{ height: this.state.chartWidth - (this.props.sizeBase * 5), width: this.state.chartWidth - (this.props.sizeBase * 5) }} 
                                        orientation={this.props.orientation} 
                                        updateOrientation={this.props.updateOrientation} 
                                        itemsChange={this.props.itemsChange} 
                                        width={this.state.width}
                                    />
                                </Animated>
                            ))
                        }
                    </div>
                </Animated>
                <Draggable 
                    position={controlledPosition} 
                    onDrag={(e, position) => this.onControlledDrag(e, position)} 
                    onStart={() => this.onControlledStart()}
                    onStop={(e, position) => this.onControlledStop(e, position)} 
                    axis="x"
                >
                    <button type="button" ref="resize" className={'resize' + (this.props.data.length !== 0 ? ' show' : ' hide')} title={t('Resize')}>
                        <span className="icon"></span>
                        <span className="sr-only">{t('Resize')}</span>
                    </button>
                </Draggable>
            </div>
        );
    }
}

export default translate('Graphics')(Graphics);
