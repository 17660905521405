import React, { Component } from 'react';
import Draggable from 'react-draggable';
import { translate } from 'react-translate';
import Screenshot from './Screenshot';
import Web from './Web';

import www from './images/www.png';
import home from './images/home.png';
import back from './images/back.png';
import screenshot from './images/screenshot.png';
import filters from './images/filters.png';
import moveWhite from './images/move-white.png';
import rotateGreen from './images/rotate-green.png';

import ScreenshotAudio from './sounds/camera-shutter.wav';

class Toolbar extends Component {
    constructor(props) {
        super(props);

        this.state = {
            defaultControlledPosition: {
                x: 2 * props.sizeBase,
                y: 2 * props.sizeBase
            },
            controlledPosition: {
                x: 2 * props.sizeBase,
                y: 2 * props.sizeBase
            },
            screenshot: false,
            web: false
        }

        this.screenshotAudio = new Audio(ScreenshotAudio);
    }

    onControlledDrag(e, position) {
        let x = position.x;
        let y = position.y;
        let maxX = window.innerWidth - this.refs.toolbar.offsetWidth - Math.abs(Math.abs(this.refs.toolbar.getBoundingClientRect().right) - Math.abs(this.refs.rotate.getBoundingClientRect().right));
        let minY = Math.abs(Math.abs(this.refs.toolbar.getBoundingClientRect().top) - Math.abs(this.refs.rotate.getBoundingClientRect().top));
        let maxY = window.innerHeight - this.refs.toolbar.offsetHeight - minY;

        if (x < 0) {
            x = 0;
        }

        if (x > maxX) {
            x = maxX;
        }

        if (y < minY) {
            y = minY;
        }

        if (y > maxY) {
            y = maxY;
        }

        this.setState(
            {
                controlledPosition: {x, y}
            }
        );
    };

    rotation() {
        this.props.onUpdateOrientation();
    }

    setDefaultPosition() {
        const y = document.body.offsetHeight - this.refs.toolbar.offsetHeight - (2 * this.props.sizeBase);
        const x = (window.innerWidth - this.refs.toolbar.offsetWidth) / 2;

        this.setState(
            {
                defaultControlledPosition: {
                    x: x,
                    y: y
                },
                controlledPosition: {
                    x: x,
                    y: y
                }
            }
        );
    }

    componentDidMount() {
        const y = document.body.offsetHeight - this.refs.toolbar.offsetHeight - (2 * this.props.sizeBase);
        const x = (window.innerWidth - this.refs.toolbar.offsetWidth) / 2;

        this.setState(
            {
                defaultControlledPosition: {
                    x: x,
                    y: y
                },
                controlledPosition: {
                    x: x,
                    y: y
                }
            }
        );

        this.props.onLoad(this.refs.toolbar.offsetWidth);
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.resetPosition !== prevProps.resetPosition) {
            this.setDefaultPosition();
        }
    }

    screenshot() {
        try {
            this.screenshotAudio.play();
        } catch(_) {}

        this.setState({ screenshot: true });
    }

    closeScreenshot() {
        this.setState({ screenshot: false });
    }

    web() {
        this.setState({ web: true });
    }

    closeWeb() {
        this.setState({ web: false });
    }

    render() {
        const {controlledPosition} = this.state;
        let { t } = this.props;

        return (
            <div className="fix-box">
                {
                    this.state.screenshot &&
                    <Screenshot 
                        onClose={() => this.closeScreenshot()} 
                        settings={this.props.screenshotSettings} 
                        orientation={this.props.orientation} 
                        virtualKeyboard={this.props.virtualKeyboard} 
                        showSearch={() => this.props.showSearch()}
                    />
                }
                {
                    this.state.web &&
                    <Web onClose={() => this.closeWeb()} textLogo={this.props.textLogo} settings={this.props.webSettings} orientation={this.props.orientation} />
                }
                <div className={'toolbar-box rotation ' + this.props.orientation}>
                    <Draggable position={controlledPosition} onDrag={(e, position) => this.onControlledDrag(e, position)}>
                        <div className="toolbar-container" ref="toolbar" style={this.state.position}>
                            <div className={'toolbar ' + this.props.orientation}>
                                <div className="options">
                                    {
                                        false &&
                                        <button type="button" ref="www" onClick={() => this.web()} className={this.state.web ? 'disabled' : ''}>
                                            <span className="rotate">
                                                <span className="icon">
                                                    <img src={www} className="img-fluid mx-auto d-block" alt={t('WWW icon')} />
                                                </span>
                                                <span className="label">{t('WWW')}</span>
                                            </span>
                                        </button>
                                    }
                                    <button type="button" ref="home" onClick={() => this.props.onClearItems()}>
                                        <span className="rotate">
                                            <span className="icon">
                                                <img src={home} className="img-fluid mx-auto d-block" alt={t('Home icon')} />
                                            </span>
                                            <span className="label">{t('Home')}</span>
                                        </span>
                                    </button>
                                    <button type="button" ref="back" onClick={() => this.props.onUndo()}>
                                        <span className="rotate">
                                            <span className="icon">
                                                <img src={back} className="img-fluid mx-auto d-block" alt={t('Undo icon')} />
                                            </span>
                                            <span className="label">{t('Undo')}</span>
                                        </span>
                                    </button>
                                    <button type="button" ref="screenshot" onClick={() => this.screenshot()} className={this.state.screenshot ? 'disabled' : ''}>
                                        <span className="rotate">
                                            <span className="icon">
                                                <img src={screenshot} className="img-fluid mx-auto d-block" alt={t('Screenshot icon')} />
                                            </span>
                                            <span className="label">{t('Screenshot')}</span>
                                        </span>
                                    </button>
                                    <button type="button" ref="filters" onClick={() => this.props.setShowFilters(true)}>
                                        <span className="rotate">
                                            <span className="icon">
                                                <img src={filters} className="img-fluid mx-auto d-block" alt={t('Filters icon')} />
                                            </span>
                                            <span className="label">{t('Filters')}</span>
                                        </span>
                                    </button>
                                </div>
                                <button type="button" ref="rotate" className={'action rotation ' + (!this.props.enableRotation ? 'disabled' : '')} onClick={() => this.rotation()} title={t('Rotate')}>
                                    <span className="content">
                                        <span className="line">
                                            <span className="circle">
                                                <img src={rotateGreen} className="img-fluid mx-auto d-block" alt={t('Rotate icon')} />
                                            </span>
                                        </span>
                                    </span>
                                    <span className="sr-only">{t('Rotate')}</span>
                                </button>
                                <button type="button" ref="move" className="action move" title={t('Move')}>
                                    <span className="content">
                                        <span className="line">
                                            <span className="circle">
                                                <img src={moveWhite} className="img-fluid mx-auto d-block" alt={t('Move icon')} />
                                            </span>
                                        </span>
                                    </span>
                                    <span className="sr-only">{t('Move')}</span>
                                </button>
                            </div>
                        </div>
                    </Draggable>
                </div>
            </div>
        );
    }
}

export default translate('Toolbar')(Toolbar);
