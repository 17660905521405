import React, { Component } from 'react';
import ScrollArea from 'react-scrollbar';
import { Alert, Badge } from 'reactstrap';
import { translate } from 'react-translate';
import { Animated } from 'react-animated-css';
import { WKT as formatWKT } from 'ol/format';

import Map from './ViewMap';
import Mode from './Mode';
import Toolbar from './Toolbar';
import Spinner from './Spinner';
import Graphics from './Graphics';
import Search from './Search';
import Item from './Item';
import Overlay from './Overlay';

import logo from './images/logo.png';
import imeplan from './images/imeplan.png';
import './sass/app.scss';
import 'ol/ol.css';

import ClickAudio from './sounds/click.wav';
import MenuSelectAudio from './sounds/menu-select.wav';

class App extends Component {
    constructor(props) {
        super(props);

        if (!this.props.error) {
            var htmlElement = document.querySelector('html');

            htmlElement.style.fontSize = this.props.config.sizeBase + 'px';

            this.state = {
                filtersStyles: {
                    width: props.config.modesWidth
                },
                orientation: 'default',
                enableRotation: true,
                items: [],
                modeActive: props.config.modes[0],
                availableSpinners: props.config.spinners.length,
                availableAreas: props.config.areas.length,
                showFilters: false,
                itemsChange: '',
                changeLayers: '1 - ' + this.getHash(),
                resetMap: 'Map - ' + this.getHash(),
                resetPosition: 'Position - ' + this.getHash(),
                searchReposition: 'Search - ' + this.getHash(),
                resetFilters: 'Filters - ' + this.getHash(),
                showGeneralData: false,
                queryFilters: '',
                modePosition: null,
                itemPosition: null,
                graphicsWidth: window.innerWidth * 0.333,
                showOverlay: true,
                toolbarWidth: 0
            };

            let spinners = [...props.config.spinners];
            let areas = [...props.config.areas];
            let items = this.state.modeActive.type === 'search' ? [...this.state.modeActive.itemsBox.colors] : [];

            this.spinners = spinners.reverse();
            this.areas = areas.reverse();
            this.items = items.reverse();
        }

        this.clickAudio = new Audio(ClickAudio);
        this.menuSelectAudio = new Audio(MenuSelectAudio);
    }

    getHash() {
        return Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
    }

    clearItems(toolbar, resetMap) {
        let _resetMap = resetMap !== undefined ? resetMap : true;

        this.setState(
            {
                items: [],
                itemsChange: this.getHash(),
                availableSpinners: this.props.config.spinners.length,
                availableAreas: this.props.config.areas.length,
                itemPosition: null,
                resetMap: _resetMap ? ('Map - ' + this.getHash()) : this.state.resetMap,
                resetPosition: 'Position - ' + this.getHash(),
                resetFilters: 'Filters - ' + this.getHash(),
                showGeneralData: false,
                orientation: 'default'
            }
        );

        let spinners = [...this.props.config.spinners];
        let areas = [...this.props.config.areas];
        let items = this.state.modeActive.type === 'search' ? [...this.state.modeActive.itemsBox.colors] : [];

        this.spinners = spinners.reverse();
        this.areas = areas.reverse();
        this.items = items.reverse();

        if (toolbar) {
            try {
                this.menuSelectAudio.play();
            } catch(_) {}

            this.setState(
                {
                    modeActive: {
                        id: 0
                    }
                },
                () => this.triggerDefaultMode()
            );
        }
    }

    triggerDefaultMode() {
        this.changeModeActive(this.props.config.modes[0].id, document.getElementById('btn-' + this.props.config.modes[0].id + '-mode').getClientRects());
    }

    undo() {
        if (this.state.items.length > 0 && this.state.items[this.state.items.length - 1].id !== 0) {
            try {
                this.menuSelectAudio.play();
            } catch(_) {}

            switch (this.state.modeActive.type) {
                case 'spinner':
                    this.removeSpinner(this.state.items[this.state.items.length - 1].id);
                    break;
                case 'area':
                    this.removeArea(this.state.items[this.state.items.length - 1].id);
                    break;
                case 'search':
                    this.removeItem(this.state.items[this.state.items.length - 1].id);
                    break;
                default:
                    break;
            }
        }
    }

    rotate(_this) {
        if (!_this.state.enableRotation) {
            return false;
        }

        const state = {
            orientation: 'default',
            enableRotation: false
        }

        switch (_this.state.orientation) {
            case 'default':
                state.orientation = 'left';
                break;
            case 'left':
                state.orientation = 'top';
                break;
            case 'top':
                state.orientation = 'right';
                break;
            case 'right':
                state.orientation = 'bottom';
                break;
            default:
                state.orientation = 'default';
                break;
        }

        if (_this.rotateSpinners) {
            _this.setState(state, () => _this.rotateSpinners());
        } else {
            _this.setState(state);
        }

        setTimeout(() => {
            if (_this.state.orientation === 'bottom') {
                const state = {
                    orientation: 'default',
                    enableRotation: true
                };

                if (_this.rotateSpinners) {
                    _this.setState(state, () => _this.rotateSpinners());
                } else {
                    _this.setState(state);
                }
            } else {
                _this.setState(
                    {
                        enableRotation: true
                    }
                );
            }
        }, 250);
    }

    rotateSpinners() {
        let items = [];

        this.state.items.forEach(item => {
            item.orientation = this.state.orientation;

            items.push(item);
        });

        this.setState(
            {
                items: items
            }
        );
    }

    urlQueryFilters(_url) {
        try {
            let url = new URL(_url);
            let urlFilters = new URL('https://localhost?' + this.state.queryFilters);
            let params = new URLSearchParams(urlFilters.search.slice(1));

            Array.from(params).forEach(value => {
                url.searchParams.append(value[0], value[1]);
            });

            _url = url.href;
        } catch(_) {

        }

        return _url;
    }

    loadDataGraphics(id, graphics, _data) {
        let _graphics = graphics;

        if (_graphics.length === 0) {
            this.addGraphicsItem(id, _data);
        } else {
            _graphics = _graphics.reverse();

            let graphic = _graphics.pop();
            let _url = graphic.url.replace('{id}', id);

            if (graphic.useFilters) {
                _url = this.urlQueryFilters(_url);
            }

            fetch(_url)
                    .then((response) => response.json())
                    .then((data) => {
                        _data.push(data);
                        this.loadDataGraphics(id, _graphics.reverse(), _data);
                    }).catch(error => {
                        _data.push({});
                        this.loadDataGraphics(id, _graphics.reverse(), _data);
                    });
        }
    }

    addGraphicsItem(id, data) {
        const items = this.state.items;
        const index = items.findIndex(item => item.id === id);

        if (index >= 0) {
            items[index].graphics = data;

            this.setState(
                {
                    items: items,
                    itemsChange: 'Graphics - Search - ' +  this.getHash()
                }
            );
        }
    }

    addInformationItem(id, data) {
        const items = this.state.items;
        const index = items.findIndex(item => item.id === id);

        if (index >= 0) {
            items[index].information = data;

            this.setState(
                {
                    items: items,
                    itemsChange: items.length + ' - ' +  this.getHash()
                }
            );
        }
    }

    addSpinner(id, position, geometry, center) {
        let result = this.state.items.find(item => item.id === id);

        if (this.state.availableSpinners > 0 && result === undefined) {
            let items = !this.state.showGeneralData ? this.state.items : [];
            const getData = this.state.modeActive.getData;
            const spinner = {
                id: id,
                information: null,
                settings: this.spinners.pop(),
                orientation: this.state.orientation,
                position: {
                    x: position[0],
                    y: position[1]
                },
                graphics: null,
                geometry: geometry,
                center: center
            };

            items.push(spinner);
            try {
                this.clickAudio.play();
            } catch(_){}

            this.setState(
                {
                    items: items,
                    availableSpinners: this.spinners.length,
                    itemsChange: items.length + ' - ' +  this.getHash(),
                    showGeneralData: false
                }
            );

            fetch(getData.info.replace('{id}', id))
                .then((response) => response.json())
                .then((data) => {
                    const information = {
                        name: data.specie && data.specie.commonName ? data.specie.commonName : '-',
                        specie: data.specie && data.specie.species ? data.specie.species : '-',
                        canopyDiameter: data.canopyDiameter ? data.canopyDiameter : '-',
                        diameter: data.diameter ? data.diameter : '-',
                        height: data.height ? data.height : '-'
                    }

                    this.addInformationItem(id, information);
                    this.loadDataGraphics(id, [...getData.graphics], []);
                });
        }
    }

    removeSpinner(id) {
        let remove = this.state.items.find(item => item.id === id);
        let items = this.state.items.filter(item => item.id !== id);

        this.spinners.push(remove.settings);
        try {
            this.clickAudio.play();
        } catch(_){}

        this.setState(
            {
                items: items,
                availableSpinners: this.spinners.length,
                itemsChange: items.length + ' - ' +  this.getHash()
            }
        );
    }

    drawArea(id, properties, geometry) {
        let result = this.state.items.find(item => item.id === id);

        if (this.state.availableAreas > 0 && result === undefined) {
            let items = !this.state.showGeneralData ? this.state.items : [];
            const getData = this.state.modeActive.getData;
            const area = {
                id: id,
                information: properties,
                settings: this.areas.pop(),
                orientation: this.state.orientation,
                position: null,
                graphics: null,
                geometry: geometry
            };

            items.push(area);
            try {
                this.clickAudio.play();
            } catch(_){}

            this.setState(
                {
                    items: items,
                    availableAreas: this.areas.length,
                    itemsChange: items.length + ' - ' +  this.getHash(),
                    resetMap: 'Hidden - ' + this.getHash(),
                    showGeneralData: false
                },
                () => {
                    setTimeout(() => {
                        this.loadDataGraphics(id, [...getData.graphics], [])
                    }, 10);
                }
            );
        } else {
            if (result !== undefined) {
                this.removeArea(result.id)
            }
        }
    }

    removeArea(id) {
        let remove = this.state.items.find(item => item.id === id);
        let items = this.state.items.filter(item => item.id !== id);

        this.areas.push(remove.settings);
        try {
            this.clickAudio.play();
        } catch(_){}

        this.setState(
            {
                items: items,
                availableAreas: this.areas.length,
                itemsChange: items.length + ' - ' +  this.getHash()
            }
        );
    }

    addItem(id, name, info) {
        let result = this.state.items.find(item => item.id === id);
        let hidden = false;

        if (result === undefined) {
            switch(this.state.modeActive.type) {
                case 'search':
                    if (this.items.length > 0) {
                        let items = !this.state.showGeneralData ? this.state.items : [];
                        const getData = this.state.modeActive.getData;
                        const item = {
                            id: id,
                            information: {
                                name: name,
                                info: info
                            },
                            settings: this.items.pop(),
                            orientation: this.state.orientation,
                            position: null,
                            graphics: null
                        };

                        items.push(item);
                        try {
                            this.clickAudio.play();
                        } catch(_){}

                        this.setState(
                            {
                                items: items,
                                itemsChange: items.length + ' - ' +  this.getHash(),
                                showGeneralData: false
                            },
                            () => this.loadDataGraphics(id, [...getData.graphics], [])
                        );
                    } else {
                        hidden = true;
                    }

                    break;
                case 'area':
                    const properties = {
                        name: name
                    };

                    const geometry = {
                        type: 'Feature',
                        feature: (new formatWKT()).readFeature(info, {
                            dataProjection: 'EPSG:4326',
                            featureProjection: 'EPSG:3857'
                        })
                    }

                    this.drawArea(id, properties, geometry);
                    break;
                default:
                    break;
            }
        } else {
            hidden = true;
        }

        if (hidden) {
            this.setState(
                {
                    itemsChange: 'Hidden - Search - ' +  this.getHash()
                }
            );
        }
    }

    removeItem(id) {
        switch(this.state.modeActive.type) {
            case 'search':
                let remove = this.state.items.find(item => item.id === id);
                let items = this.state.items.filter(item => item.id !== id);

                this.items.push(remove.settings);
                try {
                    this.clickAudio.play();
                } catch(_){}

                this.setState(
                    {
                        items: items,
                        itemsChange: items.length + ' - ' +  this.getHash(),
                        itemPosition: items.length === 0 ? null : this.state.itemPosition
                    }
                );
                break;
            case 'area':
                this.removeArea(id);
                break;
            default:
                break;
        }
    }

    showSearch(position, reposition, mode, close) {
        if (close) {
            this.setState(
                {
                    itemPosition: position,
                    itemsChange: 'Hidden - Search - ' +  this.getHash()
                }
            );
        } else {
            if (reposition) {
                this.setState(
                    {
                        itemPosition: position,
                        searchReposition: 'Search - ' +  this.getHash()
                    }
                );
            } else {
                if (this.state.itemsChange.indexOf('Search - ') !== -1) {
                    if (this.state.itemsChange.indexOf('Hidden - Search - ') !== -1 || this.state.itemsChange.indexOf('Graphics - Search - ') !== -1) {
                        this.setState(
                            {
                                itemPosition: position,
                                itemsChange: 'Search - ' +  this.getHash()
                            }
                        );
                    } else {
                        this.setState(
                            {
                                itemPosition: position,
                                itemsChange: 'Hidden - Search - ' +  this.getHash()
                            }
                        );
                    }
                } else {
                    let _itemsChange = 'Search - ' +  this.getHash();

                    if (mode) {
                        _itemsChange = 'Hidden - Search - ' +  this.getHash();
                    }

                    this.setState(
                        {
                            itemPosition: position,
                            itemsChange: _itemsChange
                        }
                    );
                }
            }
        }
    }

    changeModeActive(id, position) {
        const items = this.props.config.modes;
        const index = items.findIndex(item => item.id === id);

        this.setState(
            {
                modeActive: this.props.config.modes[index],
                changeLayers: index + ' - ' + this.getHash(),
                showGeneralData: false,
                modePosition: position,
                resetMap: 'Show - Map - ' + this.getHash()
            },
            () => {
                this.clearItems(false, false);
                this.loadGeneralData();
            }
        );
    }

    setShowFilters(state) {
        if (state) {
            try {
                this.menuSelectAudio.play();
            } catch(_) {}
        }

        this.setState(
            {
                showFilters: state
            }
        );
    }

    applyQueryFilters(query) {
        this.setState(
            {
                queryFilters: query
            },
            () => this.state.showGeneralData ? this.loadGeneralData() : this.updateGraphicsData()
        );
    }

    loadGeneralData() {
        if (this.state.modeActive.general !== undefined) {
            if (!this.state.showGeneralData && this.state.items.length === 0) {
                let { t } = this.props;

                const item = {
                    id: 0,
                    information: {
                        name: t('General')
                    },
                    settings: {
                        color: this.state.modeActive.general.color
                    },
                    orientation: this.state.orientation,
                    position: null,
                    graphics: null,
                    geometry: null
                };

                this.setState(
                    {
                        showGeneralData: true,
                        items: [item]
                    },
                    () => this.loadDataGraphics(0, [...this.state.modeActive.general.graphics], [])
                );
            } else {
                if (this.state.items.length === 1 && this.state.items[0].id === 0) {
                    this.loadDataGraphics(0, [...this.state.modeActive.general.graphics], []);
                }
            }
        }
    }

    resizeGraphics(width, hidden) {
        if (hidden) {
            this.setState(
                {
                    graphicsWidth: width,
                    itemsChange: 'Hidden - Search - ' +  this.getHash()
                }
            );
        } else {
            this.setState(
                {
                    graphicsWidth: width
                }
            );
        }
    }

    updateGraphicsData() {
        const getData = this.state.modeActive.getData;

        this.state.items.forEach(item => {
            this.loadDataGraphics(item.id, [...getData.graphics], []);
        });
    }

    enabledAdd() {
        let enabled = false;

        switch(this.state.modeActive.type) {
            case 'area':
                if (this.state.modeActive.enableSearch && this.areas.length > 0) {
                    enabled = true;
                }

                break;
            case 'search':
                if (this.items.length > 0) {
                    enabled = true;
                }

                break;
            default:
                break;
        }

        return enabled;
    }

    componentDidMount() {
        document.addEventListener('contextmenu', this._handleContextMenu);
        this.triggerDefaultMode();
    }

    componentWillUnmount() {
        document.removeEventListener('contextmenu', this._handleContextMenu);
    }

    _handleContextMenu = (event) => {
        event.preventDefault();
    };

    render() {
        let { t } = this.props;

        if (this.props.error) {
            return (
                <div className="container page-error">
                    <img src={logo} className="logo mx-auto d-block" alt="logo" />
                    <Alert color="danger">
                        {t('There was an error loading application.')}
                    </Alert>
                </div>
            );
        }

        return (
            <div className="app-container">
                <h1 className="sr-only">{this.props.config.app}</h1>
                <Animated 
                    className={'header logo rotation ' + this.state.orientation} 
                    animationIn="fadeIn" 
                    animationOut="fadeOut" 
                    animationInDuration={500} 
                    animationOutDuration={500} 
                    isVisible={this.state.items.length === 0 ? true : false}
                >
                    <img ref="logo" src={logo} className="logo img-fluid mx-auto d-block rotate disable-doubletap-to-zoom" alt={this.props.config.app} />
                </Animated>
                <Animated 
                    className={'header rotation ' + this.state.orientation} 
                    animationIn="fadeIn" 
                    animationOut="fadeOut" 
                    animationInDuration={500} 
                    animationOutDuration={500} 
                    isVisible={this.state.items.length === 0 ? true : false}
                >
                    <img ref="imeplan" src={imeplan} className="logo img-fluid mx-auto d-block rotate disable-doubletap-to-zoom" alt={this.props.config.imeplan} />
                </Animated>
                <Map 
                    app={this.props.config.app} 
                    config={Object.assign(this.props.config.map, this.state.modeActive.map)} 
                    baseLayer={this.state.modeActive.baseLayer} 
                    layers={this.state.modeActive.layers} 
                    className={'map-container rotation ' + this.state.orientation} 
                    orientation={this.state.orientation} 
                    addSpinner={(id, position, geometry, center) => this.addSpinner(id, position, geometry, center)} 
                    drawArea={(id, properties, geometry) => this.drawArea(id, properties, geometry)} 
                    areaInfo={this.state.modeActive.areaInfo} 
                    filters={this.props.config.filters} 
                    setShowFilters={(state) => this.setShowFilters(state)} 
                    applyQueryFilters={(query) => this.applyQueryFilters(query)} 
                    showFilters={this.state.showFilters} 
                    resetFilters={this.state.resetFilters} 
                    typeInteraction={this.state.modeActive.type} 
                    enableSearch={this.state.modeActive.enableSearch ? this.state.modeActive.enableSearch : false}
                    showSearch={() => this.showSearch(this.state.itemPosition ? this.state.itemPosition : this.state.modePosition, false, !this.state.itemPosition, true)}
                    items={!this.state.showGeneralData ? this.state.items : []}
                    itemsChange={this.state.itemsChange}
                    legends={this.state.modeActive.legends} 
                    sizeBase={this.props.config.sizeBase} 
                    changeLayers={this.state.changeLayers} 
                    reset={this.state.resetMap} 
                    showOverlay={() => this.setState({ showOverlay: true })}
                />
                <Animated 
                    className="modes-container d-flex flex-wrap align-content-center" 
                    animationIn="fadeIn" 
                    animationOut="fadeOut" 
                    animationInDuration={500} 
                    animationOutDuration={500} 
                    isVisible={(this.state.items.length === 0 || this.state.showGeneralData) ? true : false}
                >
                    <div className="modes disable-doubletap-to-zoom" style={this.state.filtersStyles}>
                        <div className="content">
                            {
                                this.props.config.modes.map((item) => (
                                    <Mode 
                                        key={item.id} 
                                        id={item.id} 
                                        label={item.label} 
                                        icon={item.icon} 
                                        backgroundColor={item.backgroundColor} 
                                        sizeBase={this.props.config.sizeBase} 
                                        orientation={this.state.orientation} 
                                        active={this.state.modeActive} 
                                        helpText={item.helpText ? item.helpText : false} 
                                        helpImage={item.helpImage} 
                                        onChangeMode={(id, position) => this.changeModeActive(id, position)} 
                                        loadGeneralData={() => this.loadGeneralData()} 
                                        showSearch={() => this.showSearch(this.state.modePosition, false, true)}
                                    />
                                ))
                            }
                        </div>
                    </div>
                </Animated>
                {
                    this.state.modeActive.type === "spinner" &&
                    !this.state.showGeneralData &&
                    this.state.items.map((item) => (
                        <Spinner 
                            key={item.id} 
                            id={item.id} 
                            information={item.information} 
                            settings={item.settings} 
                            position={item.position} 
                            center={item.center} 
                            sizeBase={this.props.config.sizeBase} 
                            orientation={this.state.orientation} 
                            updateOrientation={this.rotate}
                            removeSpinner={(id) => this.removeSpinner(id)} 
                            graphicsWidth={this.props.config.graphicsWidth}
                        />
                    ))
                }
                {
                    (this.state.modeActive.type === 'search' || this.state.modeActive.enableSearch) &&
                    this.state.modePosition &&
                    <Search 
                        position={this.state.itemPosition ? this.state.itemPosition : this.state.modePosition}
                        virtualKeyboard={this.props.config.virtualKeyboard} 
                        id={this.state.modeActive.id} 
                        image={this.state.modeActive.boxImage} 
                        text={this.state.modeActive.boxText} 
                        getData={this.state.modeActive.getData} 
                        addItem={(id, name, info) => this.addItem(id, name, info)} 
                        data={this.state.items} 
                        urlQueryFilters={(url) => this.urlQueryFilters(url)}
                        itemsChange={this.state.itemsChange} 
                        orientation={this.state.orientation} 
                        searchReposition={this.state.searchReposition} 
                        showSearch={() => this.showSearch(this.state.itemPosition ? this.state.itemPosition : this.state.modePosition, false, !this.state.itemPosition, true)}
                    />
                }
                {
                    (this.state.modeActive.type === 'search' || this.state.modeActive.enableSearch) &&
                    this.state.items.length > 0 &&
                    <div className={'items-box rotation ' + this.state.orientation} style={{right: this.state.graphicsWidth}}>
                        {
                            this.state.modeActive.itemsBox.title &&
                            <Badge color="secondary" className="title rotate" tag="div">{this.state.modeActive.itemsBox.title}</Badge>
                        }
                        <ScrollArea
                            speed={0.8}
                            className="box"
                            contentClassName="box-content"
                            horizontal={true} 
                        >
                            {
                                (this.state.modeActive.type === 'search' || this.state.modeActive.enableSearch) &&
                                this.state.items.map((item) => (
                                    <Item 
                                        key={item.id} 
                                        id={item.id} 
                                        information={item.information} 
                                        settings={item.settings} 
                                        icon={this.state.modeActive.itemsBox.icon}
                                        removeItem={(id) => this.removeItem(id)} 
                                        mode="item"
                                    />
                                ))
                            }
                            {
                                this.enabledAdd() &&
                                <Item 
                                    information={this.state.modeActive.itemsBox.addButton} 
                                    mode={'add'} 
                                    showSearch={(position, reposition) => this.showSearch(position, reposition)} 
                                    orientation={this.state.orientation}
                                />
                            }
                        </ScrollArea>
                    </div>
                }
                <Toolbar 
                    sizeBase={this.props.config.sizeBase} 
                    orientation={this.state.orientation} 
                    onUpdateOrientation={() => this.rotate(this)} 
                    enableRotation={this.state.enableRotation} 
                    onClearItems={() => this.clearItems(true)} 
                    textLogo={this.props.config.app} 
                    webSettings={this.props.config.web} 
                    screenshotSettings={this.props.config.screenshot} 
                    setShowFilters={(state) => this.setShowFilters(state)} 
                    onUndo={() => this.undo()} 
                    resetPosition={this.state.resetPosition} 
                    virtualKeyboard={this.props.config.virtualKeyboard} 
                    showSearch={() => this.showSearch(this.state.itemPosition ? this.state.itemPosition : this.state.modePosition, false, !this.state.itemPosition, true)} 
                    onLoad={(width) => this.setState({ toolbarWidth: width })}
                />
                {
                    this.state.items.length > 0 &&
                    <Graphics 
                        orientation={this.state.orientation} 
                        settings={this.state.showGeneralData ? this.state.modeActive.general.graphics : this.state.modeActive.getData.graphics} 
                        updateOrientation={this.rotate} 
                        data={this.state.items} 
                        sizeBase={this.props.config.sizeBase} 
                        textLogo={this.props.config.app} 
                        itemsChange={this.state.itemsChange} 
                        onResize={(width, hidden) => this.resizeGraphics(width, hidden)} 
                        minWidth={this.props.config.graphicsWidth}
                    />
                }
                <Overlay 
                    title={this.props.config.overlay} 
                    modes={this.props.config.modes} 
                    show={this.state.showOverlay} 
                    toolbarWidth={this.state.toolbarWidth} 
                    orientation={this.state.orientation} 
                    close={() => this.setState({ showOverlay: false })}
                />
            </div>
        );
    }
}

export default translate('App')(App);
