import React, { Component } from 'react';
import Draggable from 'react-draggable';
import { translate } from 'react-translate';
import { Spinner as BSpinner } from 'reactstrap';

import tree from './images/tree.png';

class Spinner extends Component {
    constructor(props) {
        super(props);

        this.state = {
            styles: {
                backgroundColor: 'transparent',
                backgroundImage: 'url(' + process.env.PUBLIC_URL + props.settings.backgroundImage + ')'
            },
            rotateStyles: {
                line: {
                    backgroundColor: props.settings.rotate.colors[0],
                },
                circle: {
                    backgroundColor: props.settings.rotate.colors[1],
                }
            },
            closeStyles: {
                line: {
                    backgroundColor: props.settings.close.colors[0],
                },
                circle: {
                    backgroundColor: props.settings.close.colors[1],
                }
            },
            moveStyles: {
                line: {
                    backgroundColor: props.settings.move.colors[0],
                },
                circle: {
                    backgroundColor: props.settings.move.colors[1],
                }
            },
            defaultControlledPosition: props.position,
            controlledPosition: props.position,
            orientation: props.orientation,
            enableRotation: true,
            customRotation: false
        }
    }

    onControlledDrag(e, position) {
        let x = position.x;
        let y = position.y;
        let maxX = window.innerWidth - this.refs.spinner.offsetWidth - this.props.graphicsWidth;
        let maxY = window.innerHeight - this.refs.spinner.offsetHeight;

        if (x < 0) {
            x = 0;
        }

        if (x > maxX) {
            x = maxX;
        }

        if (y < 0) {
            y = 0;
        }

        if (y > maxY) {
            y = maxY;
        }

        this.setState(
            {
                controlledPosition: {x, y}
            }
        );
    };

    componentDidMount() {
        let y = this.state.defaultControlledPosition.y - this.refs.spinner.offsetWidth;
        let x = this.state.defaultControlledPosition.x - this.refs.spinner.offsetHeight;

        if (y < 0) {
            y = this.state.defaultControlledPosition.y;
        }

        if (x < 0) {
            x = this.state.defaultControlledPosition.x;
        }

        if (window.innerHeight < y) {
            y = -1 * this.state.defaultControlledPosition.y;
        }

        if (window.innerWidth < x) {
            x = -1 * this.state.defaultControlledPosition.x;
        }

        this.setState(
            {
                defaultControlledPosition: {
                    x: x,
                    y: y
                },
                controlledPosition: {
                    x: x,
                    y: y
                }
            }
        );
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.orientation !== this.state.orientation && this.state.orientation === this.props.orientation && this.state.customRotation && prevState.customRotation) {
            this.setState(
                {
                    customRotation: false,
                }
            );
        }
    }

    rotation() {
        if (this.state.customRotation) {
            this.props.updateOrientation(this)
        } else {
            this.setState(
                {
                    customRotation: true,
                    orientation: this.props.orientation
                },
                () => this.props.updateOrientation(this)
            );
        }
    }

    close() {
        this.props.removeSpinner(this.props.id);
    }

    render() {
        const {controlledPosition} = this.state;
        let { t } = this.props;

        return (
            <Draggable position={controlledPosition} onDrag={(e, position) => this.onControlledDrag(e, position)}>
                <div className={'spinner-box rotation ' + (this.state.customRotation ? this.state.orientation : this.props.orientation)}>
                    <div className="spinner" ref="spinner" style={this.state.position}>
                        <div className="body rotate" style={this.state.styles}>
                            {
                                this.props.information &&
                                <div className="content d-flex flex-wrap align-content-center text-center">
                                    <div className="icon"><img src={tree} className="img-fluid mx-auto d-block" alt={t('Tree icon')} /></div>
                                    <div className="name">{this.props.information.name}</div>
                                    <div className="specie">{this.props.information.specie}</div>
                                    <div className="attributes">
                                        <ul>
                                            <li>{t('Canopy diameter')}: <strong>{this.props.information.canopyDiameter}m</strong></li>
                                            <li>{t('Diameter')}: <strong>{this.props.information.diameter}m</strong></li>
                                            <li>{t('Height')}: <strong>{this.props.information.height}m</strong></li>
                                        </ul>
                                    </div>
                                </div>
                            }
                            {
                                !this.props.information &&
                                <div className="content d-flex flex-wrap align-content-center text-center">
                                    <div className="d-flex justify-content-center">
                                        <BSpinner type="grow" style={{ color: this.props.settings.color }}>
                                            {t('Loading') + '...'}
                                        </BSpinner>
                                    </div>
                                </div>
                            }
                        </div>
                        <button type="button" ref="close" className="action close-spinner" onClick={() => this.close()} style={this.state.closeStyles.line} title={t('Close')}>
                            <span className="content">
                                <span className="line">
                                    <span className="circle" style={this.state.closeStyles.circle}>
                                        <img src={process.env.PUBLIC_URL + this.props.settings.close.icon} className="img-fluid mx-auto d-block" alt={t('Close icon')} />
                                    </span>
                                </span>
                            </span>
                            <span className="sr-only">{t('Close')}</span>
                        </button>
                        <button type="button" ref="rotate" className={'action rotation ' + (!this.state.enableRotation ? 'disabled' : '')} onClick={() => this.rotation()} style={this.state.rotateStyles.line} title={t('Rotate')}>
                            <span className="content">
                                <span className="line">
                                    <span className="circle" style={this.state.rotateStyles.circle}>
                                        <img src={process.env.PUBLIC_URL + this.props.settings.rotate.icon} className="img-fluid mx-auto d-block" alt={t('Rotate icon')} />
                                    </span>
                                </span>
                            </span>
                            <span className="sr-only">{t('Rotate')}</span>
                        </button>
                        <button type="button" ref="move" className="action move" style={this.state.moveStyles.line} title={t('Move')}>
                            <span className="content">
                                <span className="line">
                                    <span className="circle" style={this.state.moveStyles.circle}>
                                        <img src={process.env.PUBLIC_URL + this.props.settings.move.icon} className="img-fluid mx-auto d-block" alt={t('Move icon')} />
                                    </span>
                                </span>
                            </span>
                            <span className="sr-only">{t('Move')}</span>
                        </button>
                    </div>
                </div>
            </Draggable>
        );
    }
}

export default translate('Spinner')(Spinner);
