import React, { Component } from 'react';
import { translate } from 'react-translate';
import { Animated } from "react-animated-css";

import Logo from './images/Logo-AIOT.png';
import Home from './images/overlay-home.png';
import Undo from './images/overlay-undo.png';
import Screenshot from './images/overlay-screenshot.png';
import Filters from './images/overlay-filters.png';
import Rotate from './images/overlay-rotate.png';
import Move from './images/overlay-move.png';
import Close from './images/overlay-close.png';

import OverlayAudio from './sounds/overlay.wav';

class Overlay extends Component {
    constructor(props) {
        super(props);

        this.state = {
            toolbarWidth: 0,
            titleWidth: 0,
            titleTranslateY: 0,
            titleTranslateX: 0,
            titleHeight: 0
        }

        this.overlayAudio = new Audio(OverlayAudio);
    }

    componentDidMount() {
        setTimeout(() => {
            this.setState(
                {
                    toolbarWidth: this.refs.toolbar.offsetHeight,
                    titleTranslateY: this.refs.title.offsetWidth,
                    titleTranslateX: (this.refs.title.offsetWidth + this.refs.title.offsetHeight) / 2,
                    titleWidth: this.refs.title.offsetWidth,
                    titleHeight: this.refs.title.offsetHeight
                }
            );
        }, 201);
    }

    render() {
        let { t } = this.props;
        let left = (window.innerWidth + this.props.toolbarWidth) / 2;

        if (this.state.toolbarWidth !== 0 && left + this.state.toolbarWidth > window.innerWidth) {
            let move = (left + this.state.toolbarWidth) - window.innerWidth;
            left = left - move;
        }

        try {
            if (this.props.show) {
                this.overlayAudio.currentTime = 0;
                this.overlayAudio.play();
            } else {
                this.overlayAudio.pause();
            }
        } catch(_) {
        }

        return (
            <Animated 
                className={'overlay disable-doubletap-to-zoom rotation ' + this.props.orientation}
                animationIn="fadeIn" 
                animationOut="fadeOut" 
                animationInDuration={200} 
                animationOutDuration={500} 
                isVisible={this.props.show} 
                style={{
                    '--overlay-title-translate-y': this.state.titleTranslateY + 'px', 
                    '--overlay-title-translate-x': this.state.titleTranslateX + 'px',
                    '--overlay-title-width': this.state.titleWidth + 'px',
                    '--overlay-title-height': this.state.titleHeight + 'px'
                }}
            >
                <button type="button" ref="close" className="action small rotate" onClick={() => this.props.close()}>
                    <span className="icon"><img className="img-fluid mx-auto d-block" src={Close} alt={t('Close icon')} /></span>
                    <span className="sr-only">{t('Close')}</span>
                </button>
                <div className="container-overlay">
                    <h2 className="title rotate" ref="title">
                        <div className="logo" aria-hidden="true"><img src={Logo} alt={t('Logo') + ' ' + this.props.title} /></div>
                        {this.props.title}
                    </h2>
                    <div className="modes-container d-flex flex-wrap align-content-center">
                        <div className="modes">
                            <div className="content">
                                {
                                    this.props.modes.map((item) => (
                                        <div className="mode" key={item.id}>
                                            <div className="content d-flex align-items-center">
                                                <div className="icon rotate">
                                                    <img src={process.env.PUBLIC_URL + item.overlay.image} className="img-fluid mx-auto d-block" alt={t('Help icon')} />
                                                </div>
                                                <div className="text-left text rotate">
                                                    {item.overlay.text}
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                    </div>
                    <div 
                        className="toolbar text-left rotate" 
                        ref="toolbar" 
                        style={{ 
                            width: this.state.toolbarWidth === 0 ? '50vw' : this.state.toolbarWidth + 'px',
                            left: left + 'px'
                        }}
                    >
                        <ul>
                            <li className="d-flex align-items-center">
                                <img src={Home} alt={t('Home icon')} />
                                <div><strong>{t('Home')}</strong>: {t('HomeText')}</div>
                            </li>
                            <li className="d-flex align-items-center">
                                <img src={Undo} alt={t('Undo icon')} />
                                <div><strong>{t('Undo')}</strong>: {t('UndoText')}</div>
                            </li>
                            <li className="d-flex align-items-center">
                                <img src={Screenshot} alt={t('Screenshot icon')} />
                                <div><strong>{t('Screenshot')}</strong>: {t('ScreenshotText')}</div>
                            </li>
                            <li className="d-flex align-items-center">
                                <img src={Filters} alt={t('Filters icon')} />
                                <div><strong>{t('Filters')}</strong>: {t('FiltersText')}</div>
                            </li>
                            <li className="d-flex align-items-center">
                                <img src={Rotate} alt={t('Rotate icon')} />
                                <div><strong>{t('Rotate')}</strong>: {t('RotateText')}</div>
                            </li>
                            <li className="d-flex align-items-center">
                                <img src={Move} alt={t('Move icon')} />
                                <div><strong>{t('Move')}</strong>: {t('MoveText')}</div>
                            </li>
                        </ul>
                    </div>
                </div>
            </Animated>
        );
    }
}

export default translate('Overlay')(Overlay);